import React from "react";

import { Link } from "react-router-dom";

import Header from "components/Header";
import Footer from "components/Footer";
import CommentBoard from "../CommentBoard";
import LikeButton from "../LikeButton";
import { ArticleDetailRes } from "services/types/articleRes";
import { formatDate } from "utils/helper";

import author from "assets/imgs/author/sam.png";

import "../style.scss";
import "./style.scss";

type ArticleContainerProps = {
  articleData: ArticleDetailRes;
  children: React.ReactNode;
  type: string;
};

const ArticleContainer: React.FC<ArticleContainerProps> = ({
  articleData,
  children,
  type,
}) => {
  const articleNumber = articleData.articleNumber;

  const getImagePath = (type: string, articleNumber: number) => {
    try {
      return require(`../../../../assets/imgs/article/${type}/${articleNumber}/${type}${articleNumber}.png`);
    } catch (error) {
      return require(`../../../../assets/imgs/other/loading.png`);
    }
  };

  return (
    <>
      <Header />
      <main>
        <div className="pb-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="article-block">
                  <div className="article-head-block">
                    <h1 className="entry-title">{articleData.title}</h1>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="author-block">
                          <p className="author-info">
                            <Link className="author-avatar" to="/about">
                              <img src={`${author}`} alt="sam's blog author" />
                            </Link>
                            By{" "}
                            <Link to="/about">
                              <span className="author-name font-weight-bold">
                                Sam Huang
                              </span>
                            </Link>
                          </p>
                          <div className="complete-reading-block">
                            <span className="complete-date">
                              {" "}
                              {formatDate(articleData.createTime)}{" "}
                            </span>
                            <span className="has-dot">
                              <span className="reading-time">
                                {articleData.readingTime}
                              </span>{" "}
                              MINS READ
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <figure className="article-cover-img m-auto text-center">
                    <img
                      id="cover-img"
                      src={`${getImagePath(type, articleNumber)}`}
                      alt="post-title"
                    />
                  </figure>
                  {children}
                  <LikeButton
                    likeCount={articleData.likeCount}
                    id={articleData.id}
                  />
                </div>
                <hr className="wp-block-separator is-style-wide" />
                <div id="utterances-container" className="comment-borard-block">
                  <CommentBoard />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default ArticleContainer;
