import React from "react";
import ReactPaginate from "react-paginate";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import "./style.scss";

interface PaginationProps {
  totalCount: number;
  onPageChange: (selectedItem: { selected: number }) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  totalCount,
  onPageChange,
}) => {
  const handlePageChange = (selectedItem: { selected: number }) => {
    window.scrollTo(0, 0);

    onPageChange(selectedItem);
  };

  return (
    <ReactPaginate
      previousLabel={<IoIosArrowBack className="arrow-icon" />}
      nextLabel={<IoIosArrowForward className="arrow-icon" />}
      pageCount={Math.ceil(totalCount / 10)}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={handlePageChange}
      containerClassName={"pagination"}
      activeClassName={"active"}
    />
  );
};

export default Pagination;
