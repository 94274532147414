import { Link } from "react-router-dom";
import { ArticleProps } from "types/constantType";
import Gist from "react-gist";
import GitHubLink from "../components/GithubLink";

const Java6: React.FC<ArticleProps> = ({ getImagePath }) => {
  return (
    <article>
      <div className="main-content">
        <h2>前言</h2>
        <p>
          今天來介紹面試很常被問到的一題，
          <span className="code-highlight">equals</span> 和{" "}
          <span className="code-highlight">==</span> 的差異
        </p>
        <h2>==</h2>
        <p>
          如果是<strong>基本類型</strong>，則比較 「內容」是否相同
        </p>
        <blockquote>
          <p>基本類型：byte、short、int、long、float...等</p>
        </blockquote>
        <Gist id="9a7d38cdd93647f5d3355a6c7ed8af0c" file="==primitive.java" />
        <p>
          如果是<strong>物件</strong>，則比較 「記憶體位置」 是否相同
        </p>
        <blockquote>
          <p>物件：String、Integer、Long、自定義的 Class...等</p>
        </blockquote>
        <Gist id="9a7d38cdd93647f5d3355a6c7ed8af0c" file="==object.java" />
        <h2>equals</h2>
        <p>比較「內容」是否相同</p>
        <p>
          但是我們進到 <span className="code-highlight">Object.class</span>{" "}
          可以發現，<span className="code-highlight">equals</span>{" "}
          的底層實際上是通過 <span className="code-highlight">==</span> 來比較的
        </p>
        <img src={getImagePath(3)} style={{ width: "60%" }} alt="" />
        <blockquote>
          <p>
            這也是為什麼標題會寫說{" "}
            <span className="code-highlight">equals</span> 其實和{" "}
            <span className="code-highlight">==</span> 做一樣的事情
          </p>
        </blockquote>
        <p>
          此時大家可能會有疑問，那為什麼使用{" "}
          <span className="code-highlight">equals</span> 比較{" "}
          <span className="code-highlight">Integer</span>
          的時候，會是比較內容，而不是記憶體位置
        </p>
        <p>
          那是因為 <span className="code-highlight">Integer.class</span>{" "}
          有去重寫(Oeverride) <span className="code-highlight">equals</span>{" "}
          方法
        </p>
        <p>
          首先我們進到 <span className="code-highlight">Integer.class</span>{" "}
          並找到 <span className="code-highlight">equals</span> 方法
        </p>
        <img src={getImagePath(1)} style={{ width: "60%" }} alt="" />
        <p>
          可以發現 <span className="code-highlight">equals</span>{" "}
          方法會先把我們把 <span className="code-highlight">Integer</span> 轉成{" "}
          <span className="code-highlight">int</span>，再用{" "}
          <span className="code-highlight">==</span> 去比較
        </p>
        <blockquote>
          <p>
            而我們剛剛有學到如果基本類型使用{" "}
            <span className="code-highlight">==</span> 的話則是去比較「內容」
          </p>
        </blockquote>
        <h2>== 的延伸</h2>
        <p>
          對於 <span className="code-highlight">equals</span>{" "}
          方法相對來說比較不會出現問題，相比之下，使用{" "}
          <span className="code-highlight">==</span>{" "}
          就可能會遇到一些問題，特別是涉及到記憶體位置的時候
        </p>
        <h5 className="mt-50">字串的比較</h5>
        <p>
          因為 String 有 String Pool 的機制，因此是不是透過 new
          的方式會導致比較的結果不同
        </p>
        <Gist id="9a7d38cdd93647f5d3355a6c7ed8af0c" file="string.java" />
        <img src={getImagePath(2)} style={{ width: "80%" }} alt="" />
        <h5 className="mt-50">不同型別的比較</h5>
        <p>
          在 Java
          中，如果我們比較不同型別的基本類型，會發生所謂的「自動型態提升(Automatic
          Type Promotion)」
        </p>
        <Gist id="9a7d38cdd93647f5d3355a6c7ed8af0c" file="long.java" />
        <h5 className="mt-50">Integer 的比較</h5>
        <p>
          Integer 的緩存機制：會自動緩存 -128 ~ 127
          範圍內的整數，如果超出這個範圍的整數，則會創建一個新的 Integer 物件
        </p>
        <Gist id="9a7d38cdd93647f5d3355a6c7ed8af0c" file="integer.java" />
        <h2>運行速度</h2>
        <p>
          <span className="code-highlight">==</span> 的速度會比{" "}
          <span className="code-highlight">equals</span>{" "}
          快一些，因為是直接去比較記憶體位置
        </p>
        <h2>結論</h2>
        <p>
          在工作上自己還是使用 <span className="code-highlight">equals</span>{" "}
          比較多，哪怕是基本變數的比較，為了安全起見也還是一樣使用
          <span className="code-highlight">equals</span>{" "}
        </p>
        <p>
          自己完全沒遇過需要有比較兩個物件記憶體位置的時候，也可能是自己比較菜所以沒碰到這部分，如果有實際的案例歡迎分享！
        </p>
        <GitHubLink />
        <h2>參考資料</h2>
        <div className="references">
          <div>
            <Link to="https://learnku.com/articles/49654" target="_blank">
              Java基本数据类型和Integer缓存机制
            </Link>
            <span>@LearnKu</span>
          </div>
          <div>
            <Link
              to="https://rickbsr.medium.com/%E6%B7%BA%E8%AB%87-string-pool-42c37db41322"
              target="_blank"
            >
              淺談「String Pool」
            </Link>
            <span>@Medium</span>
          </div>
          <div>
            <Link to="https://worktile.com/kb/p/37775" target="_blank">
              java中的==和equals有什么区别
            </Link>
            <span>@worktile</span>
          </div>
          <div>
            <Link to="https://zhuanlan.zhihu.com/p/338350987" target="_blank">
              == 和 equals 的区别是什么？
            </Link>
            <span>@知乎</span>
          </div>
          <div>
            <Link
              to="https://www.linkedin.com/pulse/difference-between-equals-java-babar-shahzad/"
              target="_blank"
            >
              Difference Between == and equals() in Java
            </Link>
            <span>@LinkedIn</span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Java6;
