import { MonthTypes } from "types/constantType";

export const formatDate = function (timestamp: number) {
  const date = new Date(timestamp);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const months = [
    MonthTypes.JANUARY,
    MonthTypes.FEBRUARY,
    MonthTypes.MARCH,
    MonthTypes.APRIL,
    MonthTypes.MAY,
    MonthTypes.JUNE,
    MonthTypes.JULY,
    MonthTypes.AUGUST,
    MonthTypes.SEPTEMBER,
    MonthTypes.OCTOBER,
    MonthTypes.NOVEMBER,
    MonthTypes.DECEMBER,
  ];

  return `${day} ${months[monthIndex]} ${year}`;
};

// 當使用 require 語法時，Webpack 會在編譯時嘗試找到所有可能的匹配文件並包含進打包結果中
// 我使用的圖片都是 webpack 先幫我們打包好的
export const getArticleImagePath = function (
  articleType: string,
  artcileNumber: string,
  imageNumber: number
) {
  try {
    return require(`../assets/imgs/article/${articleType}/${artcileNumber}/${articleType}${artcileNumber}-${imageNumber}.png`);
  } catch (error) {
    return require(`../assets/imgs/other/loading.png`);
  }
};
