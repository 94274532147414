import React from "react";

import { useParams } from "react-router-dom";

import ArticleContainer from "./ArticleContainer";
import Loading from "components/Loading";
import NotFound from "pages/Error/NotFound";
import { useGetArticleDetailQuery } from "services/api/articleService";
import useCheckServerError from "hooks/useCheckServerError";
import { getArticleImagePath } from "utils/helper";
import { articles } from "../../../utils/articleMap";

const ArticlePage: React.FC = () => {
  const { type, articleNumber } = useParams();

  const { data, isLoading, isError, error } = useGetArticleDetailQuery({
    type: type!,
    articleNumber: articleNumber!,
  });

  useCheckServerError(isError, error);

  if (isLoading) {
    return <Loading />;
  }

  const getImagePath = function (imageNumber: number) {
    return getArticleImagePath(type!, articleNumber!, imageNumber);
  };

  const ArticleComponent = articles[`${type}${articleNumber}`];

  if (!ArticleComponent) {
    return <NotFound />;
  }

  return (
    <ArticleContainer articleData={data!.result} type={type!}>
      <ArticleComponent getImagePath={getImagePath} />
    </ArticleContainer>
  );
};

export default ArticlePage;
