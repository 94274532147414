import Error from "../../../components/Error";

const NotFound: React.FC = () => {
  return (
    <Error
      errorCode="404"
      title="請確認路徑是否正確"
      linkTitle="首頁"
      link="/"
    />
  );
};

export default NotFound;
