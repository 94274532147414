import React, { useEffect } from "react";

const CommentBoard: React.FC = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://utteranc.es/client.js";
    script.setAttribute("repo", "samtash1034/Blog-comment");
    script.setAttribute("issue-term", "pathname");
    script.setAttribute("theme", "github-light");
    script.crossOrigin = "anonymous";
    script.async = true;

    const scriptParentNode = document.getElementById("utterances-container");
    if (scriptParentNode) {
      scriptParentNode.appendChild(script);
    }
  }, []); // 如果為空陣列，'一定'會執行一次（畫面渲染完成後）

  return <div id="utterances-container" />;
};

export default CommentBoard;
