export const NoticeTypes = {
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
};

export const MonthTypes = {
  JANUARY: "JAN",
  FEBRUARY: "FEB",
  MARCH: "MAR",
  APRIL: "APR",
  MAY: "MAY",
  JUNE: "JUN",
  JULY: "JUL",
  AUGUST: "AUG",
  SEPTEMBER: "SEP",
  OCTOBER: "OCT",
  NOVEMBER: "NOV",
  DECEMBER: "DEC",
};

export interface ArticleProps {
  getImagePath: (imageNumber: number) => string;
}
