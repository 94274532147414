import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

import { articleService } from "services/api/articleService";
import { categoryService } from "services/api/categoryService";
import noticeReducer from "./reducers/noticeReducer";

const reducers = combineReducers({
  [articleService.reducerPath]: articleService.reducer,
  [categoryService.reducerPath]: categoryService.reducer,
  notice: noticeReducer,
});
export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(articleService.middleware)
      .concat(categoryService.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
