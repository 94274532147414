import { useEffect } from "react";
import { useNotice } from "./useNotice";
import { NoticeTypes } from "types/constantType";
import { useNavigate } from "react-router-dom";

// 自定義hook用於檢查數據狀態並在有需要時顯示 notice
function useCheckServerError(isError: boolean, error: any) {
  const showNotice = useNotice();
  const navigate = useNavigate();

  useEffect(() => {
    if (isError) {
      if (error.status === 400) {
        console.log(error);
        showNotice(undefined, error.data.message, NoticeTypes.WARNING);
      } else if (error.status === 404) {
        navigate("/not-found");
      } else {
        navigate("/server-error");
      }
    }
  }, [isError, error, showNotice, navigate]);
}

export default useCheckServerError;
