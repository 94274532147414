import { Link } from "react-router-dom";
import { ArticleProps } from "types/constantType";
import Gist from "react-gist";

const Java3: React.FC<ArticleProps> = ({ getImagePath }) => {
  return (
    <article>
      <div className="main-content">
        <p>比對新增、插入、移除</p>
        <h2>新增</h2>
        <Gist id="aad3385febe132a92db3800c70d3141f" file="add.java" />
        <Gist id="aad3385febe132a92db3800c70d3141f" file="add-result.txt" />
        <p>結論：ArrayList 比 LinkedList 快一些</p>
        <h2>插入</h2>
        <h2>移除</h2>
        <hr className="wp-block-separator is-style-wide" />
        <h2>參考資料</h2>
        <div className="references">
          <div>
            <Link
              to="https://docs.spring.io/spring-boot/docs/2.0.x/reference/html/using-boot-auto-configuration.html"
              target="_blank"
            >
              Auto-configuration
            </Link>
            <span>@Spring</span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Java3;
