import React, { useState } from "react";
import { useUpdateArticleLikeCountMutation } from "services/api/articleService";
import "./style.scss";

type LikeButtonProps = {
  likeCount: number;
  id: string;
};

const LikeButton: React.FC<LikeButtonProps> = ({
  likeCount: initialLikeCount,
  id,
}) => {
  const [likeCount, setLikeCount] = useState(initialLikeCount);
  const [isHeartActive, setIsHeartActive] = useState(false);
  const [updateLikeCount] = useUpdateArticleLikeCountMutation();

  const toggleHeartActive = () => {
    setIsHeartActive(!isHeartActive);
    setTimeout(() => {
      setIsHeartActive(false);
    }, 1000);
  };

  const handleClick = async () => {
    toggleHeartActive();
    setLikeCount(likeCount + 1);
    await updateLikeCount(id);
  };

  return (
    <div className="heart-btn">
      <div
        className={`content ${isHeartActive ? "heart-active" : ""}`}
        onClick={handleClick}
      >
        <span className={`heart ${isHeartActive ? "heart-active" : ""}`} />
        <span className={`text ${isHeartActive ? "heart-active" : ""}`}>
          Like
        </span>
        <span className={`heart-num ${isHeartActive ? "heart-active" : ""}`}>
          {likeCount}
        </span>
      </div>
    </div>
  );
};

export default LikeButton;
