import { Link } from "react-router-dom";
import { ArticleProps } from "types/constantType";

const Network5: React.FC<ArticleProps> = ({ getImagePath }) => {
  return (
    <article>
      <div className="main-content">
        <h2>前言</h2>
        <p>
          平常在瀏覽網站、寄送 gmail 或是看 YT
          的時候，我們很少會去注意到背後所發生的事，今天透過介紹 OSI
          網路七層架構來自己更了解不同的網路裝置的軟硬體是如何順利地互相溝通，也能讓自己更了解網路背後的運作原理
        </p>
        <h2>為何要瞭解 OSI 七層架構，OSI 是什麼？</h2>
        <p>
          OSI（Open System Interconnection Model）是由 ISO – 國際標準化組織 於
          1984 年開發。它是一個 7 層架構，每一層都具有要執行的特定功能。所有這 7
          層協同工作，在全球範圍內將數據從一個人傳輸到另一個人
        </p>
        <p>
          為什麼需要定義網路七層的標準？假設今天我 Windows 的電腦想要傳送資料給
          Mac，但其中使用的網卡、網路線和作業系統都不同，而這些東西都不一樣怎麼能互相溝通，因此我們才需要定義網路七層的標準及規範
        </p>
        <img src={getImagePath(1)} alt="OSI網路七層架構" />
        <p>下面就來介紹每一層會做的事</p>
        <h2>第 7 層 – 應用層 Application Layer</h2>
        <p>
          應用層是最<strong>接近使用者</strong>
          的一層，它是用戶和網路服務互動的窗口
        </p>
        <p>應用軟體本身並不駐留在應用層</p>
        <p>
          應用層為用戶
          <strong>提供網路服務，設定與另一端應用軟體之間的通訊</strong>
          ，像是 FTP (文件的傳輸)、HTTP/S(網頁的瀏覽)、SMTP(郵件)等等
        </p>
        <h2>第 6 層 – 表達層 Presentation Layer</h2>
        <p>表達層拿到應用層傳送過來的資料後，主要會對資料做三件事</p>
        <p>
          <span className="network5-bg-grey">傳送方</span>：
        </p>
        <p />
        <p>１. 編碼：把資料編碼成 0 和 1，轉換成電腦看得懂的語言</p>
        <p>
          ２. 壓縮資料：減少檔案傳輸的大小 ，就像 5MB 的文件壓縮後可以變到 2MB
        </p>
        <p>３. 加密</p>
        <p>
          以次類推，<span className="network5-bg-grey">接收方</span>
          的表達層做的事就是把會議層的資料拿來做解密、解壓縮、解碼
        </p>
        <h2>第 5 層 – 會議層 Session Layer</h2>
        <p>
          當有兩台設備需要互相通訊的時候，會議層會創建 Session
          讓這兩台設備可以溝通
        </p>
        <p>會議層允許兩個系統以半雙工或全雙工的方式開始互相通訊</p>
        <p>雙方通訊如果完成，會議層就會關掉 Session，終止連接</p>
        <p>
          會議層還會在數據傳輸的時候設置檢查點，如果 Session
          意外中斷，可以從上一個檢查點恢復數據傳輸
        </p>
        <h2>第 4 層 – 傳輸層 Transport Layer</h2>
        <p>
          <span className="network5-bg-grey">傳送方</span>
          的傳輸層會把資料切割成好幾段
        </p>
        <p>
          <span className="network5-bg-grey">接收方</span>
          的傳輸層則會把切割的資料組起來
        </p>
        <p>
          <span className="network5-bg-grey">接收方</span>
          還會執行錯誤的控制，從而確保接收的資料完整無缺，如果不完整，可要求重新傳輸
        </p>
        <p>
          傳輸層還負責流量的控制，確保快速連線的傳送者不會淹沒具有低速連線的接受者
        </p>
        <p>
          TCP 和 UDP 的端口號工作也在這一層(TCP 80 port 就是要連網頁，TCP 21
          port 就是要連我們的 FTP)
        </p>
        <h2>第 3 層 – 網路層 Network Layer</h2>
        <p>
          <span className="network5-bg-grey">傳送方</span>
          會把我們的資料加上來源 IP 以及目的 IP，然後 Router 會根據目的IP
          去查詢路由表，再丟給下一台 Router
        </p>
        <blockquote>
          <p>
            對於 Router 不熟悉的人可參考這篇：
            <Link to="#">Hub、Switch 和 Router 的差異及運作原理</Link>
          </p>
        </blockquote>
        <h2>第 2 層 – 資料連結層 Data Link Layer</h2>
        <p>資料連結層提供節點到節點的數據傳輸</p>
        <p>這一層主要由兩個部分組成：</p>
        <ul>
          <li>
            邏輯連結控制 Logical Link Control (LLC)：識別網路協議、執行錯誤檢查
          </li>
          <li>
            媒體存取控制 Media Access Control (MAC)：把數據加上來源 MAC 以及目的
            MAC，並使用 MAC address 連接設備並定義傳輸和接收數據的權限
          </li>
        </ul>
        <p>
          <span className="network5-bg-grey">傳送方</span>
          會把資料轉成訊號，然後丟給第一層（實體層）的網路線（如果使用光纖訊號會被轉成光波、使用無線網路則會轉成電磁波）
        </p>
        <p>
          <span className="network5-bg-grey">接收方</span>
          則會將接收到訊號或光波號後轉換成資料
        </p>
        <h2>第 1 層 – 實體層 Physical Layer</h2>
        <p>實體層負責網路節點之間的物理電纜或無線連接</p>
        <p>
          實體層定義了連接設備的連接器、電纜或無線技術，並負責傳輸原始數據，即一系列
          0 和 1，同時負責位元速率的控制
        </p>
        <p>
          實體層還定義了數據在兩個連接的設備之間流動的方式以及各種傳輸模式像是單工、半雙工和全雙工
        </p>
        <hr className="wp-block-separator is-style-wide" />

        <h2>參考資料</h2>
        <div className="references">
          <div>
            <Link to="https://youtu.be/gxoIrBFfpDU" target="_blank">
              網路七層與TCP/IP
            </Link>
            <span>@阿彬電腦</span>
          </div>
          <div>
            <Link
              to="https://www.networkworld.com/article/964816/the-osi-model-explained-and-how-to-easily-remember-its-7-layers.html"
              target="_blank"
            >
              The OSI model explained
            </Link>
            <span>@NETWORKWORLD</span>
          </div>
          <div>
            <Link
              to="https://www.geeksforgeeks.org/open-systems-interconnection-model-osi/"
              target="_blank"
            >
              What is OSI Model? – Layers of OSI Model
            </Link>
            <span>@geeksforgeeks</span>
          </div>
          <div>
            <Link
              to="https://www.youtube.com/watch?v=vv4y_uOneC0&ab_channel=TechTerms"
              target="_blank"
            >
              OSI Model Explained
            </Link>
            <span>@TechTerms</span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Network5;
