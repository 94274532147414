import { useDispatch } from "react-redux";

export const useNotice = () => {
  const dispatch = useDispatch();

  const showNotice = (
    e?: React.MouseEvent,
    message?: string,
    noticeType?: string
  ) => {
    if (e) {
      e.preventDefault();
    }

    // 發送 action 到 redux store
    dispatch({ type: "SHOW_NOTICE", payload: { message, noticeType } });
  };

  return showNotice;
};
