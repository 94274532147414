import { Link } from "react-router-dom";
import { ArticleProps } from "types/constantType";

const Reading1: React.FC<ArticleProps> = ({ getImagePath }) => {
  return (
    <article>
      <div className="main-content">
        <h2>前言</h2>
        <p>
          這本書主要在告訴我們如何提升自己的
          <strong className="text-danger">心智強度</strong>，並且列出 13
          項我們不應該做的事。這 13
          件事主要是幫我們找出因應人生挑戰更好的方法，以避開那些陷阱
        </p>
        <h2>ㄧ、不浪費時間自怨自艾</h2>
        <blockquote>
          <p>
            自憐是破壞力最強的非藥性毒品，容易上癮，給人一時的快感，進而脫離現實
          </p>
        </blockquote>
        <p>
          我們一定會經歷過人生的苦痛與哀傷，但有時候我們會沈浸在哀傷與不幸之中，想說自己遇到的問題比別人多很多，抱怨不公平，世界常常與我們作對等等
        </p>
        <p>
          我們必須要停止自怨自艾，那只是在浪費時間，並且引發我們更多的負面情緒，還可能使我們忽略生命中的美好
        </p>
        <p>
          自怨自艾讓人老是覺得
          <strong className="text-danger">我受到虧欠</strong>，感恩則讓人覺得
          <strong className="text-danger">我得到的比應得的還多</strong>
        </p>

        <p>
          我們可以時常保持感恩，並且感到自怨自艾時馬上轉念，有機會也可以多多參與活動
        </p>
        <h2>二、不放棄主導權</h2>
        <blockquote>
          <p>
            我們憎恨敵人時，等於是讓他們主導了我們。我們的睡眠、食慾、血壓、健康、快樂都落入了他們的掌控
          </p>
        </blockquote>
        <blockquote></blockquote>
        <p>讓別人控制我們的想法、感覺和行為時，我們就不可能心智堅強</p>
        <p>
          如果喪失主導權，我們會對批評變得很敏感，讓別人左右我們的感受，長久下來會造成很大的內耗
        </p>
        <h2>三、不怕改變</h2>
        <blockquote>
          <p>
            意志力不是有些人有，有些人沒有...而是有些人願意改變，有些人不願意
          </p>
        </blockquote>
        <p>我們很常騙自己那個壞習慣「沒那麼糟」，為自己找藉口</p>
        <p>
          很多人不願意改變是為了迴避不安，但他們往往低估了自己承受不安的能力
        </p>
        <p>專注在小改變，不要一次就想要達到特定的目標</p>
        <h2>四、不在意無法掌控的事情</h2>
        <blockquote>
          <p>你也許無法控制發生在你身上的是，但你可以決定不被它們擊倒</p>
        </blockquote>
        <p>
          每件事都在掌握中，確實令人心安。
          <strong className="text-danger">
            但以為自己能掌控一切，卻可能是個問題
          </strong>
        </p>
        <p>
          想要掌控一切是浪費時間和精力，並且會對別人很嚴苛，覺得失敗都是自己的問題
        </p>
        <p>專注在我們能掌控的事情上</p>
        <p>練習學會「接受」</p>
        <h2>五、不會想要處處迎合他人</h2>
        <blockquote>
          <p>在意他人想法，永為人囚</p>
        </blockquote>
        <p>有時候我們可能會覺得自己需要為別人的感覺負責</p>
        <p>想辦法過符合個人信念的生活，而不是讓多數人開心的生活</p>
        <h2>六、不怕審慎冒險</h2>
        <blockquote>
          <p>做事不要太膽怯拘謹，人生就是要實驗，實驗越多越好</p>
        </blockquote>
        <p>不審慎冒險，難以成大事</p>
        <h2>七、不沉緬於過往</h2>
        <blockquote>
          <p>沈浸於往昔無法療癒傷痛，唯有活在當下才是良方</p>
        </blockquote>
        <p>有時候過去的遺憾令我們難以釋懷</p>
        <p>花太多時間思考，要是當初做出稍微不同的選擇，人生會變成怎麼樣</p>
        <p>沈湎過去就無法充分準備未來，阻礙我們前進</p>
        <p>
          拒絕沈浸在過去，不是假裝往事從未發生過。我們要去接納過去，這樣才能活在當下。
        </p>
        <p>
          <strong>與過去和解使我們更堅強</strong>
        </p>
        <h2>八、不重蹈覆徹</h2>
        <blockquote>
          <p>只有我們無法從中學習的錯誤，才是真正的錯誤</p>
        </blockquote>
        <h2>九、不嫉妒他人成就</h2>
        <blockquote>
          <p>嫉妒就像自己喝毒藥，卻希望能毒死敵人</p>
        </blockquote>
        <p>
          避免和他人比較，就像比較頻果和橘子，我們都各有不同特才華、技巧和生活經驗，相互比較不是衡量自我價值的準確方式
        </p>
        <p>
          <strong className="text-danger">努力練習為他人喝采</strong>
        </p>
        <h2>十、不輕言放棄</h2>
        <blockquote>
          <p>失敗就是成功的過程，迴避失敗的人也遠離了成功</p>
        </blockquote>
        <p>有心練習比天賦更重要</p>
        <h2>十一、不怕獨處</h2>
        <blockquote>
          <p>人類的悲苦，都是源自於無法獨自靜坐在房間裡</p>
        </blockquote>
        <p>每個月至少安排一天的「獨處日」</p>
        <p>每天思考你的進度和目標</p>
        <h2>十二、不怨天尤人</h2>
        <blockquote>
          <p>別到處怨天尤人，這個世界沒有欠你什麼，它比你先來</p>
        </blockquote>
        <p>
          以為自己是特例，所以不太需要努力，或不需要經歷別人遇到的過程，那樣的想法都不太健康
        </p>
        <p>注意自己是否有「自命不凡」的心態</p>
        <p>人生本來就很不公平</p>
        <p>虛心接受批評，承認缺失和弱點</p>
        <p>謙虛使我們更堅強</p>
        <h2>十三、不求立竿見影</h2>
        <blockquote>
          <p>耐力、毅力、努力是所向無敵的成功組合</p>
        </blockquote>
        <p>看不到立即的成效，你就認為那樣做沒效</p>
        <p>我們很容易缺乏耐心、高估自己的能力而且低估改變需要的時間</p>
        <p>進度不見得都很明顯</p>
        <p>過程中慶祝每個里程碑的達成</p>
        <h2>結語</h2>
        <p>
          我們要維持<strong>心智強度</strong>
        </p>
        <p>就跟體力一樣，心智強度也需要持續鍛鍊，永遠都有進步的空間</p>
        <p>心智強度一旦疏於維持或強化，就會像肌肉一樣萎縮</p>
        <hr className="wp-block-separator is-style-wide" />
        <h2>參考資料</h2>
        <div className="references">
          <div>
            <Link
              to="https://www.books.com.tw/products/0010694737?gad_source=1&gclid=CjwKCAiAg9urBhB_EiwAgw88meny8pMhJD_ElFpniyzc0SBTn_pBy0zZCB28pV2f85ixW1xBJko0phoCynkQAvD_BwE"
              target="_blank"
            >
              告別玻璃心的十三件事：心智強者，不做這些事
            </Link>
            <span>@博客來</span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Reading1;
