import { Link } from "react-router-dom";
import Gist from "react-gist";
import { ArticleProps } from "types/constantType";
import GitHubLink from "../components/GithubLink";

const Java9: React.FC<ArticleProps> = ({ getImagePath }) => {
  return (
    <article>
      <div className="main-content">
        <h2>什麼是 MyBatis Generator</h2>
        <p>
          MyBatis Generator（MBG）是一種代碼生成工具，它主要會根據我們資料庫的
          Table，去產生相對應的 POJO 和 SQL Mapper 文件（包含基本的 CRUD 語句）
        </p>
        <blockquote>
          <p>
            這邊的 POJO 我習慣以<strong> Model </strong>
            結尾來命名，可能有些人單純叫 Table 名，或是 Entity
          </p>
        </blockquote>
        <h2>建立專案</h2>
        <p>
          先建立一個 SpringBoot 專案，我這邊的使用的是 Java 21 和 SpringBoot
          3.2，資料庫使用 MySQL
        </p>
        <h2>導入 Maven</h2>
        <Gist
          id="65587f9413c6b0ae968be3439612830c"
          file="mybatis-generator-maven.xml"
        />
        <p>
          要注意 plugin 裡面的 dependencies 也要配置資料庫的連線，因為 MBG
          是吃這裡的依賴，否則會出現下面這段錯誤
        </p>
        <p>
          <span className="code-highlight">
            Execution default-cli of goal
            org.mybatis.generator:mybatis-generator-maven-plugin:1.4.2:generate
            failed: Exception getting JDBC Driver
          </span>
        </p>
        <h2>建立 Table</h2>
        <Gist id="65587f9413c6b0ae968be3439612830c" file="insert-data.sql" />
        <h2>建立配置檔</h2>
        <p>在專案的 resources 資料夾底下建立一個 generatorConfig.xml</p>
        <blockquote>
          <p>
            注意這裡的名稱一定要叫做 generatorConfig，因為 MBG
            預設就是抓這個檔案，如果想改名需要另外配置
          </p>
        </blockquote>
        <img src={getImagePath(1)} alt="generatorConfig" width="50%" />
        <h2>generatorConfig</h2>
        <p>
          這邊只提供基本的設定，MBG 還提供其它配置，有興趣的可以參考
          <Link to="https://juejin.cn/post/6844903982582743048#heading-3">
            這篇
          </Link>
        </p>
        <p>
          連線資訊我是使用動態的方式載入，要注意引入配置文件的副檔名只能是
          <span className="text-danger"> properties</span>，yaml
          在這邊是會失效的
        </p>
        <Gist
          id="65587f9413c6b0ae968be3439612830c"
          file="generatorConfig.xml"
        />
        <h2>自動生成檔案</h2>
        <p>
          配置完成後，點擊右邊的 Maven 圖示 =&gt; Plugins =&gt;
          mybatis-generator，點擊兩下 mybatis-generator:generator
        </p>
        <blockquote>
          <p>
            不用 Intellij Idea 的人可以到 Terminal 輸入{" "}
            <span className="code-highlight">
              mvn mybatis-generator:generate
            </span>{" "}
            ，效果是一樣的
          </p>
        </blockquote>
        <img
          src={getImagePath(3)}
          alt="Intellij mybatis-generator:generate"
          width="80%"
        />
        <p>BUILD SECCESS 後，就會看到 Model、Mapper、XML 都幫我們自動產生</p>
        <img
          src={getImagePath(2)}
          alt="MBGmvn 產生的 mapper model xml"
          width="80%"
        />
        <p>可以看到 Model 產生對應的欄位和 Getter、Setter </p>
        <img src={getImagePath(5)} alt="MBG 產生的 model class" width="80%" />
        <p>而 Mapper 則是產生基本的 CRUD 語句</p>
        <img
          src={getImagePath(6)}
          alt="MBG 產生的 mapper interface"
          width="80%"
        />
        <p>都確認沒問題，這樣就算成功啦！</p>
        <h2>對已存在資料重新跑一次自動生成</h2>
        <p>
          如果今天的資料庫欄位有異動時，想要重新跑一次自動生成，那 Model 和
          Mapper 都不會有問題，它會直接把更新的內容覆蓋上去
        </p>
        <p>可是我的 XML 則會出現重複的 SQL 語句</p>
        <p>
          可以看到我搜尋{" "}
          <span className="code-highlight">deleteByPrimaryKey</span>{" "}
          ，它出現兩個一樣的
        </p>
        <img src={getImagePath(7)} alt="MBG xml 產生的重複的 SQL 語句" />
        <p>最好的解決辦法就是把 XML 刪掉，這樣就不會有問題了</p>
        <p>
          這時候如果有自定義 SQL 在 Mapper 和
          XML，就要把原本寫的先複製下來，再去執行自動生成，然後再貼上去
        </p>
        <h2>結論</h2>
        <GitHubLink repoLink="https://github.com/samtash1034/Blog-MyBatis-Generator" />
        <h2>參考資料</h2>
        <div className="references">
          <div>
            <Link
              to="https://juejin.cn/post/6844903982582743048"
              target="_blank"
            >
              MyBatis Generator 超详细配置
            </Link>
            <span>@稀土掘金</span>
          </div>
          <div>
            <Link
              to="https://mybatis.org/generator/quickstart.html"
              target="_blank"
            >
              MyBatis Generator
            </Link>
            <span>@mybatis.org</span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Java9;
