import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";

import "./style.scss";

const NoticeBox: React.FC = () => {
  const dispatch = useDispatch();
  // useSelector 鉤子來訂閱 notice 部分狀態的組件將會觸發重新渲染
  const { showNotice, message, noticeType } = useSelector(
    (state: RootState) => state.notice
  );

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (showNotice) {
      timeoutId = setTimeout(() => {
        dispatch({ type: "HIDE_NOTICE" });
      }, 1500);
    }

    return () => {
      if (timeoutId) {
        // 返回一個函數是一種清理（或稱為清除、撤銷）的機制
        // 清除計時器
        clearTimeout(timeoutId);
      }
    };
  }, [showNotice, dispatch]);

  if (!showNotice) return null;

  return (
    <div
      className={`notice-box notice-${noticeType}`}
      onClick={() => dispatch({ type: "HIDE_NOTICE" })}
    >
      <p>{message}</p>
    </div>
  );
};

export default NoticeBox;
