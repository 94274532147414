import { Link } from "react-router-dom";
import { ArticleProps } from "types/constantType";

const Other2: React.FC<ArticleProps> = ({ getImagePath }) => {
  return (
    <article>
      <div className="main-content">
        <h2>Base64 主要做了什麼</h2>
        <p>
          使用 Base64 的主要原因是為了將<strong>二進制數據</strong>轉換為 64 個
          <strong> ASCII 可用字元</strong>
        </p>
        <img
          src={getImagePath(1)}
          width="80%"
          alt="將二進制數據轉換為 64 個 ASCII 可用字元"
        />
        <h2>什麼是二進制數據</h2>
        <p>
          二進制數據是由 0 和 1
          組成的數據。二進制數據可以用來表示各種類型的資料，例如
          <strong>文字</strong>、<strong>圖片</strong>、<strong>影片</strong>、
          <strong>程式</strong>等等
        </p>
        <h2>Base「64」的意思</h2>
        <p>Base64 轉換出來的可用字元只會包含以下幾個：</p>
        <ul>
          <li>A-Z（26個字母）</li>
          <li>a-z（26個字母）</li>
          <li>0-9（10個數字）</li>
          <li>+ 和 /（2個特殊符號）</li>
          <li>=（用於填充結尾部份）</li>
        </ul>
        <p>
          除了 = 填充用的，26 + 26 + 10 + 2 = 64，剛好為 64 個字元，因此叫做
          Base「64」
        </p>
        <h2>為什麼要使用 Base64</h2>
        <p>
          因為某些系統僅限於 ASCII 字元，使用 Base64 可以將此資料「偽裝」為
          ASCII，從而幫助該資料通過驗證
        </p>
        <p>
          許多常見的網路協議（HTTP、SMTP、 FTP等）都可以處理 ASCII
          字元，但在傳輸二進制的時候可能會出現問題
        </p>
        <blockquote>
          <p>
            二進制數據中一些特殊字節（如
            0x00、0xFF）或是換行符號，在某些系統可能會有特殊的處理方式，這樣可能導致資料損壞或不完整
          </p>
        </blockquote>
        <h2>Base64 優缺點</h2>
        <h5>優點</h5>
        <ul>
          <li>
            容易使用：Base64 編碼的資料可以輕鬆包含在 URL、JSON、XML
            或其他基於文字的格式中
          </li>
          <li>
            完整性：相較於二進制數據中特殊字符可能導致數據毀損，Base64
            可以避免這些問題
          </li>
        </ul>
        <h5>缺點</h5>
        <ul>
          <li>
            數據膨脹：它使資料增加了約 33%
            的大小，如果數據量太大可能導致性能下降
          </li>
          <li>
            不安全：Base64 只是一種<strong>編碼</strong>
            ，而非加密，因此不要用它來使用機密訊息
          </li>
        </ul>
        <h2>Base64 的出現</h2>
        <p>
          很早之前，電子郵件的功能完全基於文本的，但隨著時間的推移，圖像和媒體（音頻、視頻）等附件的需求出現。當這些附件透過網路發送時（以二進位資料的形式），原始形式的二進位資料被損壞的可能性很高，為了解決這個問題，才有了
          Base64
        </p>
        <h2>參考資料</h2>
        <div className="references">
          <div>
            <Link
              to="https://blog.logto.io/zh-TW/all-about-base64"
              target="_blank"
            >
              你需要知道的 Base64 百科全書
            </Link>
            <span>@Logto</span>
          </div>
          <div>
            <Link
              to="https://www.freecodecamp.org/news/what-is-base64-encoding/#heading-why-use-base64"
              target="_blank"
            >
              What is base64 Encoding and Why is it Necessary?
            </Link>
            <span>@freecodecamp</span>
          </div>
          <div>
            <Link
              to="https://matthung0807.blogspot.com/2022/06/what-is-base64-encode.html"
              target="_blank"
            >
              Base64編碼是什麼？
            </Link>
            <span>@菜鳥工程師 肉豬</span>
          </div>
          <div>
            <Link
              to="https://carger.tips/%E6%B7%B1%E5%85%A5%E4%BA%86%E8%A7%A3base64%E7%B7%A8%E7%A2%BC%E5%92%8C%E8%A7%A3%E7%A2%BC%E6%87%89%E7%94%A8%E5%8E%9F%E7%90%86%E5%8F%8A%E6%B3%A8%E6%84%8F%E4%BA%8B%E9%A0%85"
              target="_blank"
            >
              深入了解Base64編碼和解碼：應用、原理及注意事項{" "}
            </Link>
            <span>@卡哥</span>
          </div>
          <div>
            <Link
              to="https://www.quora.com/What-is-the-purpose-of-base64-encoding-why-not-just-use-binary"
              target="_blank"
            >
              What is the purpose of base64 encoding, why not just use binary?
            </Link>
            <span>@Quora</span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Other2;
