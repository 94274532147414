import React from "react";

import { Link } from "react-router-dom";

import Header from "components/Header";
import Footer from "components/Footer";
import Loading from "components/Loading";
import { MonthTypes } from "../../types/constantType";
import { useGetArticleTimelineQuery } from "services/api/articleService";
import useCheckServerError from "hooks/useCheckServerError";

import "./style.scss";

const Timeline: React.FC = () => {
  const { data, isLoading, isError, error } = useGetArticleTimelineQuery();
  useCheckServerError(isError, error);

  if (isLoading) {
    return <Loading />;
  }

  const formatDate = (createTime: number) => {
    const date = new Date(createTime);
    const monthIndex = date.getMonth();
    const values = Object.values(MonthTypes);
    const month = values[monthIndex];

    return `${date.getDate()}${" "}${month}`;
  };

  return (
    <>
      <Header />
      <div className="timeline">
        {data?.result?.map((article) => (
          <div key={article.id} className="entry">
            <div className={`time-block ${article.title ? "" : "big-circle"}`}>
              <p>
                {article.title
                  ? formatDate(article.createTime)
                  : new Date(article.createTime).getFullYear()}
              </p>
            </div>
            {article.title && (
              <div className="title-block">
                <Link
                  to={`/article/${article.category}/${article.articleNumber}`}
                >
                  {article.title}
                </Link>
              </div>
            )}
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
};

export default Timeline;
