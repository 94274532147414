import { Link } from "react-router-dom";
import { ArticleProps } from "types/constantType";

const Network1: React.FC<ArticleProps> = ({ getImagePath }) => {
  return (
    <article>
      <div className="main-content">
        <h2>前言</h2>
        <p>
          有沒有曾經困惑過輸入網址後背後的原理到底是什麼、IP 和 Domain
          又有什麼差異
        </p>
        <h2>什麼是 DNS？</h2>
        <p>
          在網際網路的世界，當我們要與其它台電腦通訊，都需要有 IP
          地址，所以平常我們在瀏覽器輸入 www.google.com，www.google.com
          這段域名就會被 DNS 解析成 142.251.42.228，也就是它對應的 IP 地址，拿到
          IP 地址後，我們就可以順利的與 google 進行通訊
        </p>
        <img src={getImagePath(1)} alt="" />
        <p>當然 DNS 做的事沒有這麼單純，後面會介紹更多</p>
        <h2>直接輸入 IP 不行嗎?</h2>
        <p>當然可以，只是 DNS 提供了幾個優勢：</p>
        <ul>
          <li>可讀性：一堆數字和英文字母，哪個比較好記顯而易見</li>
          <li>
            靈活性：假設一個網站的 IP 地址換了，我們只需要更新 DNS 指向的
            IP，而不用大費周章通知大家說我們有新的 IP 了
          </li>
          <li>路由管理：DNS 讓我們可以容易地管理子網域</li>
        </ul>
        <h2>DNS結構圖</h2>
        <p>這邊先大概看一下圖就好，下面會更詳細的介紹</p>
        <p>第一層為 Root</p>
        <p>第二層為 TLD（Top Level Domain）</p>
        <img src={getImagePath(2)} alt="" />
        <h2>DNS 運作流程</h2>
        <div className="number-title">
          <p>1</p>
          <p>本地電腦</p>
        </div>
        <p>
          當我們今天想要造訪 1111 人力銀行時，我們在瀏覽器中輸入
          1111.com.tw，此時電腦會去查詢你的<strong>瀏覽器緩存</strong>
          有沒有對應的 IP 紀錄，同時還會查詢<strong>本地文件</strong>
          裡面有沒有對應的紀錄，如果有紀錄，就不用進行後面的步驟了，但是如果沒找到，它就會把這個查詢送到
          <strong>Resolver Server</strong>
        </p>
        <img src={getImagePath(3)} alt="" />
        <div className="number-title">
          <p>2</p>
          <p>Resolver Server</p>
        </div>
        <p>
          Resolver Server 的中文名稱叫做「解析器伺服器」，基本上就是你的 ISP
          或網路服務提供商，當 Resolver 收到查詢時，它會檢查自己的緩存中有沒有
          1111.com.tw 的 IP 地址，如果沒找到，就會把查詢送到
          <strong>Root Name Server</strong>
        </p>
        <blockquote>
          <p>
            ISP (Internet Service Provider)
            網際網路服務供應商：只提供網際網路存取服務的公司。通常大型的電信公司都會兼任網際網路服務供應商，像是中華電信的
            HiNet，台灣大寬頻等等
          </p>
        </blockquote>
        <img src={getImagePath(4)} alt="" />
        <div className="number-title">
          <p>3</p>
          <p>Root Name Server</p>
        </div>
        <p>
          Root Name Server 的中文名稱叫做「根名稱伺服器」，主要管理
          com、net、tw、jp 這些頂級域名伺服器（TLD)
        </p>
        <p>
          根名稱伺服器 位於 DNS 結構的最頂層，目前全世界有 13 個根伺服器，並且由
          12 個獨立機構所營運的
        </p>
        <p>目前 12 個獨立機構的 Root Name Server 在全世界有 1644 個節點</p>
        <img src={getImagePath(5)} alt="" />
        <p>
          當 Root Name Server 收到 IP 地址後，它不會知道 IP 地址是什麼，但是
          Root Name Server 會將解析器指向到 TLD Server
        </p>
        <img src={getImagePath(6)} alt="" />
        <div className="number-title">
          <p>4</p>
          <p>TLD Server</p>
        </div>
        <p>
          TLD（Top Level
          Domain）：紀錄著所有最頂層的域名（上方樹狀圖的第二層），像是
          .com、.net、.org，特定國家的網域像是 .us、.tw 等等
        </p>
        <p>
          TLD Server 收到 tw 後，會查詢下一層，並且回應 com.tw 伺服器給 Resolver
        </p>
        <p>
          當 Resolver 收到 com.tw 伺服器，會把查詢交給下一層，也就是
          <strong>Authoritative Name Server</strong>
        </p>
        <img src={getImagePath(7)} alt="" />
        <div className="number-title mt-20">
          <p>5</p>
          <p>Authoritative Name Server</p>
        </div>
        <p>
          Authoritative Name Server 中文名稱叫做「權威名稱伺服器」
          ，這邊的權威伺服器可能會有一個或多個（看域名，如果往下很多層就會有多個權威伺服器），它們會知道所有關於域名的訊息，包括
          IP 地址
        </p>
        <p>當 com.tw 伺服器收到訊息後，會回應 1111.com.tw 給 DNS Resolver</p>
        <p>
          DNS Resolver 收到 1111.com.tw 後再去向 1111.com.tw
          伺服器發送查詢，最後回應 1111.com.tw 的 IP 地址
        </p>
        <img src={getImagePath(8)} alt="" />
        <p>
          當 DNS Resolver 收到 IP
          地址後，它會先把資料存進去快取，短期內如果有人再次訪問 1111.com.tw
          時，就不用重新走過整個流程
        </p>
        <p>電腦在收到 IP 地址後就可以順利的訪問網頁了</p>
        <img src={getImagePath(9)} alt="" />
        <hr className="wp-block-separator is-style-wide" />

        <h2>參考資料</h2>
        <div className="references">
          <div>
            <Link
              to="https://www.cloudflare.com/zh-tw/learning/dns/what-is-dns/"
              target="_blank"
            >
              什麼是 DNS？ | DNS 的工作方式
            </Link>
            <span>@CLOUDFLARE</span>
          </div>
          <div>
            <Link
              to="https://aws.amazon.com/tw/route53/what-is-dns/"
              target="_blank"
            >
              什麼是 DNS？
            </Link>
            <span>@AWS</span>
          </div>
          <div>
            <Link
              to="https://www.youtube.com/watch?v=mpQZVYPuDGU&ab_channel=PowerCertAnimatedVideos"
              target="_blank"
            >
              How a DNS Server (Domain Name System) works.
            </Link>
            <span>@PowerCert Animated Videos</span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Network1;
