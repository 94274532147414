import React from "react";

import Typewriter from "typewriter-effect";

import { homePageTypingText } from "utils/config";

const TypewriterComponent: React.FC = () => {
  return (
    <div className="text-muted">
      <span className="d-inline">
        <Typewriter
          onInit={(typewriter) => {
            typewriter
              .typeString(homePageTypingText[0])
              .pauseFor(500)
              .deleteAll()
              .typeString(homePageTypingText[1])
              .pauseFor(500)
              .deleteAll()
              .start();
          }}
          options={{
            autoStart: true,
            loop: true,
            deleteSpeed: 30,
          }}
        />
      </span>
    </div>
  );
};

export default TypewriterComponent;
