import { Link } from "react-router-dom";
import { ArticleProps } from "types/constantType";
import GitHubLink from "../components/GithubLink";

const Java8: React.FC<ArticleProps> = ({ getImagePath }) => {
  return (
    <article>
      <div className="main-content">
        <h2>前言</h2>
        <p>
          <Link to={"/article/java/7"}>上一篇</Link> 主要介紹了 HashMap
          存資料時會做什麼事，也介紹了雜湊碰撞的其中一個解決方法 -
          「封閉尋址法」
        </p>
        <p>
          今天會繼續介紹碰撞的另外三種解法，它們都被歸納在「開放尋址法」，分別是：
        </p>
        <p>線性探測（Linear Probing）</p>
        <p>二元探測（Quadratic Probing）</p>
        <p>雙重雜湊（Double Hashing）</p>
        <h2>前置作業</h2>
        <p>
          這邊故意讓 <span className="code-highlight">hash(c)</span> 的 index 和{" "}
          <span className="code-highlight">hash(a)</span> 一樣都為{" "}
          <span className="code-highlight">0</span>
        </p>
        <img src={getImagePath(1)} alt="HashTable" />
        <h2>線性探測（Linear Probing）</h2>
        <p>
          今天我們想要將{" "}
          <span className="code-highlight">&lt;c, item3&gt;</span> 插入到 index{" "}
          <span className="code-highlight">0</span>
          ，卻因為已經有值而發生碰撞
        </p>
        <img src={getImagePath(2)} alt="線性探測（Linear Probing）" />
        <p>於是 item3 開始往下找，一格一格慢慢找，直到找到可用空間來插入</p>
        <img src={getImagePath(3)} alt="線性探測（Linear Probing）" />
        <blockquote>
          <p>會從當前碰撞的位置由左至右尋找可用空間，如果到底再從頭繼續尋找</p>
        </blockquote>
        <h2>二元探測（Quadratic Probing）</h2>
        <p>
          二元探測和線性探測很像，只是他是以<strong>平方</strong>
          的方式往下探測，直到找到可用空間
        </p>
        <img src={getImagePath(4)} alt="二元探測（Quadratic Probing）" />
        <h2>雙重雜湊（Double Hashing）</h2>
        <p>如果遇到衝突，則換個方法來計算 index，以此來變更儲存的位置</p>
        <img src={getImagePath(5)} alt="雙重雜湊（Double Hashing）" />
        <h2>結論</h2>
        <GitHubLink />
        <h2>參考資料</h2>
        <div className="references">
          <div>
            <Link
              to="Hash Table Data structure
              "
              target="_blank"
            >
              Hash Table Data structure
            </Link>
            <span>@tutorialspoint</span>
          </div>
          <div>
            <Link
              to="https://www.youtube.com/watch?v=JDPv1UCXpCU&ab_channel=1MINLearning"
              target="_blank"
            >
              Quadratic probing explained in 1 minute
            </Link>
            <span>@Youtube</span>
          </div>
          <div>
            <Link
              to="https://www.youtube.com/watch?v=vPvxEDyxI2w&list=PLhxdaTcUMi3nRM5mtOdQgO4VEtAEsTiYd&index=21&t=304s&ab_channel=%E5%A5%AEgame%E7%8E%8B%E7%B4%AB%E6%A5%93"
              target="_blank"
            >
              輕鬆搞懂資料結構: 雜湊(hash)
            </Link>
            <span>@Youtube</span>
          </div>
          <div>
            <Link
              to="https://www.baeldung.com/cs/hashing-linear-probing"
              target="_blank"
            >
              Hashing – Linear Probing
            </Link>
            <span>@Baeldung</span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Java8;
