import { ArticleProps } from "types/constantType";

const Other1: React.FC<ArticleProps> = ({ getImagePath }) => {
  return (
    <article>
      <div className="main-content">
        <h2>動機</h2>
        <p>
          其實一開始自己對於部落格沒有太大的想法，只是想要把工作的事情筆記下來，久而久之，筆記越來越多，想說有沒有一個可以統整的方式，後來就選擇寫部落格。
        </p>
        <p></p>
        <p>
          在寫部落格的時候，就希望自己呈現出來的東西是「高質量」、「易於理解」的，但最重要的還是內容的正確性
        </p>
        <h2>架構</h2>
        <p>前端：React + Ts + Scss</p>
        <p>後端：SpringBoot</p>
        <p>資料庫：MySQL</p>
        <p>
          前端完成後我會 Build 成一包並放到後端的 static
          資料夾中，這樣就只要部署 SpringBoot 就好了
        </p>

        <h2>部署的演進史</h2>
        <p>分享一下在建立部落格時，我過往做的選擇，提供給有興趣的人參考</p>
        <img
          src={getImagePath(1)}
          width="80%"
          alt="wordpress aws digitalocean zeabur部署的演進史"
        />
        <h3>WordPress</h3>
        <p>
          一開始架站是選擇
          WordPress，只要把文章完成就好，其它的像是部署、備份、SEO，監控這些都在後台幫你做好
        </p>
        <p>但後來因為自己想要高度客製化就棄用了</p>
        <h3 className="mt-30">AWS EC2</h3>
        <p>
          因為自己有後端和資料庫，因此一定要一台 VM
          才能滿足的我的需求，所以當初矇矇懂懂的我就去找了市占率最高的公有雲來租借主機
        </p>
        <p>
          免費額度用完後收到帳單，發現一個月竟然要 2000
          多，真的太貴了，因此選擇新的伺服器來架設
        </p>
        <h3 className="mt-30">DigitalOcean</h3>
        <p>
          逃離 AWS 後，自己是選擇了 DigtialOcean
          來架設我的部落格，不得不說這個網站的 UI 做得很好，使用者體驗也很不錯
        </p>
        <p>
          一開始選擇約一個月 500 元的主機，但網速真的太慢，到後面才升級到 900 元
        </p>
        <p>雖然也是不便宜都這樣也時刻提醒自己更不應該放棄寫 Blog</p>
        <h3 className="mt-30">Zeabur</h3>
        <p>主打著「部署服務從未如此簡單」的名號，我就想說好來體驗看看</p>
        <p>
          進去後才發現 Zeabur 是一個 Paas
          平台，讓我可以專注於開發，而不用擔心伺服器的問題
        </p>
        <p>
          進去設定好 GitHub
          連結後然後直接部署，5分鐘內直接完成，它會直接判斷你使用的是什麼語言、框架以及對應的版本並直接做部署，我連
          Dockerfile 都不用寫了
        </p>
        <p>
          用下來目前體驗都不錯，而且它是根據流量來收費的，對我這種沒什麼人看的部落格來說是最好的了（？
        </p>

        <h2>心得</h2>
        <p>
          身為一個後端，平常接觸前端的機會少之又少，因此當初遇到最大的困難點就是畫面，好不容易補足一些前端的知識，想說可以不靠框架來架設網站，才發現很費力又很費時，因此又去學習
          React，用了框架之後開發的效率大大的提升！
        </p>
        <p>
          自己覺得寫部落格一方面是吸收新知，另一方面是把知識內化，並嘗試用淺顯易懂的方式讓別人完全理解，因此有些地方都會插入一些圖片來當作輔助，來更好的呈現我想表達的內容
        </p>
        <p>
          目前給自己的目標是一個月產生一篇文章，期許自己 5
          年後回過頭來看有達成目標～
        </p>
      </div>
    </article>
  );
};

export default Other1;
