import { Link } from "react-router-dom";
import { ArticleProps } from "types/constantType";
import Gist from "react-gist";

const Java2: React.FC<ArticleProps> = ({ getImagePath }) => {
  return (
    <article>
      <div className="main-content">
        <h2>前言</h2>
        <p>
          如果大家有使用過 Spring
          就知道，當我們只是要建立一個簡單的專案，就會卡某在一個地方很久。沒錯，就是配置設定檔，
          一堆東西要配置，直到 Spring Boot
          的出現才解救了我們，因此今天就來介紹大大減少我們開發時間的 Auto
          Configuration
        </p>
        <h2>前置作業</h2>
        <p>
          在開始之前，我們先在 application.properties 加上這行，讓我們在 console
          中可以看見更多詳細的資訊
        </p>
        <img src={getImagePath(11)} alt="application.properties設定" />
        <h2>什麼是 SpringBoot Auto Configuration</h2>
        <p>
          Spring Boot Auto Configuration (自動配置)
          提供許多預設配置，其目的就是為了減少我們花在配置上的時間，而能夠更專心地處理商業邏輯
        </p>
        <p>接著來介紹比較常見到的自動配置類別</p>
        <h2>JacksonHttpMessageConvertersConfiguration</h2>
        <p>這個類別會自動的幫我們配置 Jackson</p>
        <blockquote>
          <p>
            Jackson，這是一個用於將 Java 物件轉換為 JSON 以及將 JSON 轉換回 Java
            物件的流行庫
          </p>
        </blockquote>
        <p>
          當我們在 SpringBoot 中的 Controller 層 return
          一個物件回去時，SpringBoot 會自動幫我們把物件轉換成 Json 的格式
        </p>
        <Gist
          id="260a33eb988bf32fa716be93f03175ff"
          file="studentController.java"
        />
        <img src={getImagePath(1)} alt="response結果" />
        <blockquote>
          <p>
            可以下載{" "}
            <Link
              to="https://chromewebstore.google.com/detail/json-formatter/bcjindcccaagfpapjjmafapmmgkkhgoa?hl=zh-TW"
              target="blank"
            >
              JSON Formatter
            </Link>
            ，是由 Google 所提供的插件，可讀性會更好一些
          </p>
        </blockquote>
        <p>
          當初得到 JSON 也沒有多想，想不到是 SpringBoot
          在背後偷偷幫我們完成了轉換的工作
        </p>
        <p>在 console 中可以看到 jacksonhttpmessageconverters</p>
        <img
          src={getImagePath(2)}
          alt="console 中的 jacksonhttpmessageconverters"
        />
        <h2>DispatcherServletAutoConfiguration</h2>
        <p>這個類別會幫我們處理 request 的請求</p>
        <p>
          SpringBoot 會先經由 DispatcherServlet
          （控制器入口，負責分發請求），並且根據 url /
          後面的路徑，去尋找我們對應的方法
        </p>
        <p>在 console 中可以看到 DispatcherServletAutoConfiguration</p>
        <img
          src={getImagePath(6)}
          alt="console 中的 DispatcherServletAutoConfiguration"
        />
        <h2>ErrorMvcAutoConfiguration</h2>
        <p>
          當我們啟動 SpringBoot 卻輸入錯誤的網址，會跳轉到 Error
          Page，相信大家對這頁面都不陌生
        </p>
        <img src={getImagePath(3)} alt="springboot error page" />
        <p>在 console 中可以看到 ErrorMvcAutoConfiguration</p>
        <img
          src={getImagePath(4)}
          alt="console 中的 ErrorMvcAutoConfiguration"
        />
        <p>
          進到 ErrorMvcAutoConfiguration 這個 class，可以看到 Error Page 上的
          html 和 css 是在這裡被定義的
        </p>
        <img
          src={getImagePath(5)}
          alt="Error Page 在源碼中定義的 html 和 css"
        />
        <h2>spring-boot-starter-web</h2>
        <p>
          最後要來介紹使用 spingboot 建立 Web 後端時一定會添加的 dependency：
          <strong>spring-boot-starter-web</strong>
        </p>
        <p>
          相信大家在最一開始使用 Spring 而非 Spring Boot 時，都要在 pom.xml
          加一堆依賴，像是 spring-webmvc、jackson-databind、log4j、spring
          Test、Mockito、Junit 等等，非常的麻煩
        </p>
        <blockquote>
          <p>
            除了 pom.xml，還有一堆配置文件像是
            web.xml、context.xml...現在回想起來又更離不開 SpringBoot 了
          </p>
        </blockquote>
        <p>
          但因為 Spring Boot 的誕生，所有的配置都變得非常簡單，以
          spring-boot-starter-web 這個依賴為例
        </p>
        <p>在 pom.xml中，找到 spring-boot-starter-web，並按 ctrl + 左鍵進去</p>
        <img src={getImagePath(10)} alt="" />
        <p>
          會發現 spring-boot-starter-web dependency
          已經默默幫我們做了許多事，像是 json、tomcat、springMVC 等等
        </p>
        <h2>結論</h2>
        <p>
          介紹到這邊，相信大家對於 Spring Boot Auto Configuration
          有初步的了解．如果沒有
          SpringBoot，就會發非常多的時間在配置，到頭來配置的時間都比開發的時間還要長，這也是為什麼
          Spring Boot 會誕生以及它的 Auto Configuration 這麼重要了
        </p>
        <hr className="wp-block-separator is-style-wide" />
        <h2>參考資料</h2>
        <div className="references">
          <div>
            <Link
              to="https://docs.spring.io/spring-boot/docs/2.0.x/reference/html/using-boot-auto-configuration.html"
              target="_blank"
            >
              Auto-configuration
            </Link>
            <span>@Spring</span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Java2;
