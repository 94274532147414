import { Link } from "react-router-dom";
import { ArticleProps } from "types/constantType";

const Network6: React.FC<ArticleProps> = ({ getImagePath }) => {
  return (
    <article>
      <div className="main-content">
        <h2>前言</h2>
        <p>
          在網路中，Switch、Hubs 和 Routes
          看起來很相似，但它們處理數據的方式存在著差異，再加上這三樣東西可以被整合到一個設備，使我們更難區分他們
        </p>
        <h2>Hub 集線器</h2>
        <p>
          作用：<strong>把內網中的網路設備連接起來</strong>
        </p>
        <p>支持乙太網路的接口，可以連接多種網路設備</p>
        <p>
          Hub <strong className="text-danger">不是智能</strong>
          的，不會過濾任何的數據，Hub 只知道端口上是否連接了設備
        </p>
        <p>
          封包進到 Hub 的一個端口，因為 Hub 根本不知道這個封包是要給誰的，因此
          Hub 會把封包廣播到每一台有連接 Hub 設備的端口上
        </p>
        <p>
          舉例來說：當今天 A 電腦要傳一筆數據給 D 電腦，其他的電腦 (B、C)
          因為在同一台 Hub 上，所以也會接收到數據，即使數據不是要發給它們的
        </p>
        <img src={getImagePath(1)} alt="hub集線器" />
        <p>Hub 造成的缺點：</p>
        <ul>
          <li>不安全</li>
          <li>造成網路流量、頻寬的浪費</li>
        </ul>
        <p>
          如今 Hub
          這種設備也慢慢比較少在使用，但也有一些有心人士想要惡意攔截資料，那他就會在一條線中間串接
          Hub，透過 Hub 廣播的特性，就可以攔截到這條線傳輸的所有資料
        </p>
        <h2>Switch 交換器</h2>
        <p>
          作用：<strong>把內網中的網路設備連接起來</strong>
        </p>
        <p>
          支持乙太網路的接口，可以連接多種網路設備，到這邊為止都與 Hub 一模一樣
        </p>
        <p>
          不一樣的地方是，Switch 它是
          <strong className="text-danger">智能</strong>的，它能夠學習
        </p>
        <p>
          Switch 會把端口上設備的 MAC address 存到一張表 (參考下方的 SWITCH
          TABLE，它比 HUB TABLE 多出了 MAC ADDRESS)
        </p>
        <blockquote>
          <p>
            MAC address (媒體存取控制位址)，有興趣的可以參考{" "}
            <Link to="/article/network/2">
              MAC Address 是什麼？它和 IP Address 有什麼差異
            </Link>
          </p>
        </blockquote>
        <p>
          當一個電腦傳送封包到 Switch，因為有了 Mac Address ，所以 Switch
          知道要傳給哪一台電腦，而不是像 Hub 會把封包廣播到所有端口
        </p>
        <p>
          舉例來說：當 A 電腦想發送封包給 D 電腦，Switch 此時會去檢查 SWITCH
          TABLE 的 MAC ADDRESS，並把封包交到指定的電腦 (D電腦) ，這時候 B、C
          電腦就不會收到來自 A 電腦的封包
        </p>
        <img src={getImagePath(2)} alt="switch 交換機" />
        <p>因此 Switch 比 Hub 受歡迎許多，因為它減少了不必要的網路流量</p>
        <h2>Switch 和 Hub 的差異</h2>
        <p>Hub 只檢測物理上連接到它的設備</p>
        <p>
          Switch 因為有記錄每台設備的 Mac Address，因此可以傳送封包到指定目的地
        </p>
        <p>Hub 和 Switch 被用在區域網路(LAN)內交換數據</p>
        <p>
          它們都<strong>不能和外網交換數據</strong>
          (像是Google首頁)，因為要和外網交換數據，必須要有能讀取 IP 的設備，然而
          Hub 和 Switch 不讀取 IP Address，因此我們需要 Router 路由器
        </p>
        <h2>Router 路由器</h2>
        <p>
          當一個封包被 Router 所接收時，Router 會檢查數據的 IP
          address，並判斷這個封包是發給它的網路還是其它網路
        </p>
        <p>
          如果 Router 判斷這個封包是給它的，那 Router
          就會接收，如果不是，它就會這個封包發給其他網路
        </p>
        <p>路由器實際上就是網路的出路口（gateway，又稱閘道器）</p>
        <p>
          舉例來說：我們這邊用不同顏色來代表它們的 IP
          address，外網上有許多的封包在傳遞，今天紅色 Router
          只會接收紅色封包，因為它知道只有紅色封包的目的地是這邊，而其它封包不屬於這裡，Router
          就會拒絕接收
        </p>
        <h5 className="mt-30 mb--35">接收封包</h5>
        <img src={getImagePath(3)} alt="router路由器" />
        <p>
          以此類推，當紅色 Router 這邊的網路想要傳送封包給橘色的
          Router，那它就需要傳相對應的封包（橘色封包），這樣橘色 Router
          就會接收到這個橘色封包
        </p>
        <h5 className="mt-30 mb--35">傳輸封包</h5>
        <img src={getImagePath(4)} alt="router路由器" />
        <h2>結論</h2>
        <p>Hub 和 Switch 是用來創造網路，而 Router 是用來連接網路</p>
        <blockquote>
          <p>
            有興趣的人可以接續學習{" "}
            <Link to="/article/network/7">Router 路由器延伸學習-網段</Link>
          </p>
        </blockquote>
        <hr className="wp-block-separator is-style-wide" />
        <h2>參考資料</h2>
        <div className="references">
          <div>
            <Link
              to="https://www.youtube.com/watch?v=1z0ULvg_pW8&t=128s"
              target="_blank"
            >
              集線器，交換機和路由器解釋 - 有什麼區別？
            </Link>
            <span>@PowerCert Animated VideosD</span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Network6;
