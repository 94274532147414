import React from "react";
import { Link } from "react-router-dom";
import { IoPricetagsOutline } from "react-icons/io5";

import Header from "components/Header";
import Footer from "components/Footer";
import Pagination from "components/Pagination";
import Loading from "components/Loading";
import InnerServerError from "pages/Error/InnerServerError";
import Item from "./item";
import TypewriterComponent from "./tyepwritter";
import useCheckServerError from "hooks/useCheckServerError";
import usePageClick from "../../hooks/uesPageClick";
import { useGetHomePageArticleQuery } from "services/api/articleService";

import featured from "assets/imgs/homePage/featured.png";
import "./style.scss";

const HomePage: React.FC = () => {
  const { currentPage, handlePageClick } = usePageClick();

  const { data, isLoading, isError, error } = useGetHomePageArticleQuery({
    page: currentPage,
    limit: 10,
  });
  useCheckServerError(isError, error);

  if (isLoading) {
    return <Loading />;
  }

  if (isError || !data) {
    return <InnerServerError />;
  }

  return (
    <>
      <Header />
      <main>
        <div className="feature">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 align-self-center">
                <TypewriterComponent />
                <h2>
                  Hello, I'm <span>Sam</span>
                </h2>
                <h3>Welcome to my blog</h3>
              </div>
              <div className="col-lg-6 text-end d-none d-lg-block">
                <img src={featured} alt="sam's blog" />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="hot-tag-container">
            <div className="row align-self-center">
              <div className="col-md-4 align-self-center" />
              <div className="col-md-8 text-md-end align-self-center">
                <IoPricetagsOutline className="hot-tag-icon" />
                <p className="hot-tag-block">Hot tags：</p>
                <ul className="list-inline d-inline-block tags">
                  <li className="list-inline-item">
                    <Link to="/category/java">#Java</Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="/category/network">#網路</Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="/category/security">#Security</Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="/category/reading">#讀書心得</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="article-pagnation-container">
            <div id="articleListSection" className="row">
              <div className="main-article col-lg-8 hover-up position-relative">
                <div className="position-relative">
                  <div className="img img-overlay img-hover-slide position-relative">
                    <div className="content-block">
                      <div className="tags">
                        <Link to="/category/other">
                          <span className="tag ohter-tag-color">other</span>
                        </Link>
                      </div>
                      <h3 className="post-title">
                        <Link to="/article/other/1">
                          關於這個部落格的大小事
                        </Link>
                      </h3>
                      <div className="main-article-info">
                        <span>05 MAY 2024</span>
                        <span className="has-dot">5 MINS READ</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {data!.result.data.map((item, index) => (
                <Item key={index} item={item} />
              ))}
            </div>
            <div className="row">
              <div className="col-12">
                <Pagination
                  totalCount={data!.result.totalCount}
                  onPageChange={handlePageClick}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default HomePage;
