import { NoticeTypes } from "types/constantType";

type NoticeAction =
  | { type: "SHOW_NOTICE"; payload: { message: string; noticeType: string } }
  | { type: "HIDE_NOTICE" };

const initialState = {
  showNotice: false,
  message: "",
  noticeType: NoticeTypes.SUCCESS,
};

interface NoticeState {
  showNotice: boolean;
  message: string;
  noticeType: string;
}

// 接收 action
const noticeReducer = (
  state: NoticeState = initialState,
  action: NoticeAction
) => {
  // 更新狀態(state)
  switch (action.type) {
    case "SHOW_NOTICE":
      return {
        ...state, // 這保留了 initialState 中的所有現有鍵值對
        showNotice: true, // 改變 showNotice
        message: action.payload.message || "此功能正在努力中！！", // 改變 message
        noticeType: action.payload.noticeType || NoticeTypes.SUCCESS,
      };
    case "HIDE_NOTICE":
      return {
        ...state,
        showNotice: false,
      };
    default:
      return state;
  }
};

export default noticeReducer;
