import { Link } from "react-router-dom";
import { ArticleProps } from "types/constantType";

const Network2: React.FC<ArticleProps> = ({ getImagePath }) => {
  return (
    <article>
      <div className="main-content">
        <h2>前言</h2>
        <p>
          我們最常聽到 Mac 是指蘋果的電腦，那如果我們在 Mac 後面加上 Address
          變成 MAC Address 時，它指的就是一個完全不同的概念
        </p>
        <h2>什麼是 MAC Address</h2>
        <p>
          MAC Address (Media Access Control Address)，中文叫做
          「媒體存取控制位址」， 它是
          <strong>每個網路設備用來在網路上唯一標識自己的一串符號</strong>
          ，就很像是身分證字號，只是它綁在硬體上面，因此世界上任何地方沒有兩台設備具有相同的
          MAC Address
        </p>
        <p>Mac address 也稱為 Physical (物理) 或 Hardware (硬體) address</p>
        <p>下面是常見具有 Mac Address 的設備</p>
        <img src={getImagePath(1)} alt="" />
        <h2>MAC Address 的組成</h2>
        <p>
          MAC Address 是一個6字節的十六進制數字組成，它可以包含任何數字和字母
        </p>
        <p>
          <span className="text-success">00-04-5A-</span>63-A1-66
        </p>
        <p>
          前面綠色的三個字節為 <strong>NIC 的製造商</strong>，像是
          Intel、Broadcom、TP-LINK 等等
        </p>
        <blockquote>
          <p>
            NIC (Network Interface Card)
            網路卡：是一塊被設計用來允許電腦在網路上進行通訊的電腦硬體
          </p>
        </blockquote>
        <p>
          後面的三個字節是<strong>製造商的唯一編號</strong>
          ，用於標示網路上的每個設備
        </p>
        <p>在不同的作業軟體上，Mac address 會以不同的方式格式化</p>
        <ul>
          <li>
            <p>Windos 數字字母之間是破折號</p>
          </li>
          <img
            className="mt-0"
            src={getImagePath(2)}
            alt="Windows 中 MAC Address 的格式示意圖"
          />
          <li>
            <p>Mac 及 Linux 數字字母之間是冒號</p>
          </li>
          <img
            className="mt-0"
            src={getImagePath(3)}
            alt="Mac 和 Linux 中 MAC Address 的格式示意圖"
          />
        </ul>
        <h2>MAC address 的具體用途</h2>
        <p>
          Mac address 的目的是讓<strong>網路設備可以互相通訊</strong>
        </p>
        <p>當一個設備想要與另一個設備通訊時，最終都是使用 MAC address 完成的</p>
        <p>
          在當前的互聯網時代，大多數設備都通過乙太網路電纜連接或通過 Wi-Fi
          無線連接。這兩種方法都使用 MAC address 來識別網絡上的設備。
        </p>
        <h2>MAC address 和 IP address 的差異</h2>
        <p>
          這時候你可能會問，如果設備之間的通訊都是使用 Mac
          address，那為什麼還要有 IP ，IP 不是也是唯一的嗎？
        </p>
        <p>
          Public IP 確實是唯一的，但是 Public IP
          <strong>可以定期更改</strong>，可能我們過去幾個月擁有某個 IP
          address，但網路服務提供商或網路管理人員可以將我們的 IP address
          更改為不同的 IP address，但 MAC address 不會改變，它們是永久性的
        </p>
        <p>
          當網路設備想要透過 TCP/IP 通訊，它就需要
          <strong className="text-danger">IP 和 MAC address</strong>
        </p>
        <ul>
          <li>
            IP address 用於<strong>定位設備</strong>
            ：可以比喻成住址，住址會告訴我們所在的國家、城市以及街道，但它不一定告訴我們誰住在房子裡面
          </li>
          <li>
            MAC address 用於<strong>識別設備</strong>
            ：可以比喻成人的名字，它告訴我們誰住在房子裡
          </li>
        </ul>
        <img src={getImagePath(4)} alt="" />
        <p>當設備想要互相通訊時，最終都會使用 MAC address</p>
        <h2>IP 和 MAC Address 的通訊原理</h2>
        <p>想要拿到另一台設備的 MAC address 就必須先有它的 IP address</p>
        <div className="number-title">
          <p>1</p>
          <p>同一個網段</p>
        </div>
        <p>
          現在 A 電腦想要和 C 電腦進行通訊，而 A 電腦已經拿到 C 電腦的 IP
          了，但還需要 C 電腦的 MAC address
        </p>
        <p>
          此時 A 電腦會向同一個網段的所有設備發送 ARP 廣播來找到 C 電腦的 MAC
          address
        </p>
        <blockquote>
          <p>
            ARP (Address Resolution Protocol) 位址解析協定：負責將 IP address
            轉換成 MAC address 的一種通訊協定，有興趣的人可以參考 ARP (Address
            Resolution Protocol)
          </p>
        </blockquote>
        <p>
          A 電腦會向同一網段的所有設備問說：「誰的 IP address 是
          <span className="bg-light-green">192.168.0.40</span>？」
        </p>
        <p>C 電腦：「是我」</p>
        <p>A 電腦：「請給我你的 MAC address」</p>
        <p>
          C 電腦：「好的，我的 MAC address 是
          <span className="bg-light-orange">04-06-2C-77-4C-2Y</span>」
        </p>
        <img src={getImagePath(5)} alt="" />
        <div className="number-title">
          <p>2</p>
          <p>不同網段</p>
        </div>
        <p>
          當 A 電腦想要傳送數據到 google.com，它就需要 Google 網路服務器的 MAC
          address，此時 A 電腦發現 Google 的 IP address 和它在不同網段底下，因此
          A 電腦就需要透過 Router 來傳送數據
        </p>
        <p>
          電腦 A 發送 ARP 廣播得到 RouterA 的 MAC address，一但有了 MAC
          address，就可以把數據傳給 RouterA
        </p>
        <p>
          接著 RouterA 發送 ARP 廣播得到 RouterB 的 MAC address，得到 MAC
          address 後把數據傳給 RouterB，然後 RouterB 發送 ARP 廣播得到 RouterC
          的 MAC address...以此類推，最終拿到 google.com 的 MAC
          address，就可以把資料傳給 google 了
        </p>
        <img src={getImagePath(6)} alt="" />
        <hr className="wp-block-separator is-style-wide" />

        <h2>參考資料</h2>
        <div className="references">
          <div>
            <Link
              to="https://zh.wikipedia.org/zh-tw/%E7%BD%91%E5%8D%A1"
              target="_blank"
            >
              網路卡
            </Link>
            <span>@維基百科</span>
          </div>
          <div>
            <Link
              to="https://zh.wikipedia.org/zh-tw/%E5%9C%B0%E5%9D%80%E8%A7%A3%E6%9E%90%E5%8D%8F%E8%AE%AE"
              target="_blank"
            >
              位址解析協定
            </Link>
            <span>@維基百科</span>
          </div>
          <div>
            <Link
              to="https://www.youtube.com/watch?v=TIiQiw7fpsU"
              target="_blank"
            >
              MAC Address Explained
            </Link>
            <span>@PowerCert Animated Videos</span>
          </div>
          <div>
            <Link to="https://www.kocpc.com.tw/archives/310582" target="_blank">
              如何在 Windows 10 上查找 MAC 位址（實體位址）
            </Link>
            <span>@電腦王阿達</span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Network2;
