import React from "react";

import { Link } from "react-router-dom";

import { formatDate } from "utils/helper";
import { CategoryArticleRes } from "types/apiResponse/categoryRes";

type ItemProps = {
  item: CategoryArticleRes;
  type: string;
};

const Item: React.FC<ItemProps> = ({ item, type }) => {
  const { articleNumber, title, likeCount, readingTime, completeTime } = item;

  const getImagePath = (type: string, articleNumber: number) => {
    try {
      return require(`../../assets/imgs/article/${type}/${articleNumber}/${type}${articleNumber}.png`);
    } catch (error) {
      return require(`../../assets/imgs/other/loading.png`);
    }
  };

  return (
    <article className="transition-normal">
      <div className="row">
        <div className="col-md-4">
          <div className="position-relative">
            <div
              className="img img-hover-slide position-relative"
              style={{
                backgroundImage: `url(${getImagePath(type, articleNumber)})`,
              }}
            >
              <Link
                className="img-link"
                to={`/article/${type}/${articleNumber}`}
              ></Link>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="post-content">
            <div className="entry-meta mb-1"></div>
            <h5 className="post-title">
              <a href={`/article/${type}/${articleNumber}`}>{title}</a>
            </h5>
            <div className="entry-meta post-date-read-like text-uppercase">
              <span className="post-on">{formatDate(completeTime)}</span>
              <span className="post-on has-dot">{readingTime} mins read</span>
              <span className="post-on has-dot">{likeCount} Like</span>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Item;
