import type { RouteObject } from "react-router-dom";
import HomePage from "pages/HomePage";
import CategoryPage from "pages/Category";
import ArticlePage from "pages/Article/components/ArticlePage";
import About from "pages/About";
import TimeLine from "pages/TimeLine";
import InnerServerError from "pages/Error/InnerServerError";
import NotFound from "pages/Error/NotFound";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <HomePage />,
    children: [],
  },
  {
    path: "/category/:type",
    element: <CategoryPage />,
    children: [],
  },
  {
    path: "/article/:type/:articleNumber",
    element: <ArticlePage />,
    children: [],
  },
  {
    path: "/about",
    element: <About />,
    children: [],
  },
  {
    path: "/timeline",
    element: <TimeLine />,
    children: [],
  },
  {
    path: "/server-error",
    element: <InnerServerError />,
    children: [],
  },
  {
    path: "*",
    element: <NotFound />,
    children: [],
  },
];

export default routes;
