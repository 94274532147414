import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiResponse, ApiPageResponse } from "utils/apiResponse";
import {
  HomePageArticleRes,
  ArticleDetailRes,
  ArtilceTimelineRes,
} from "services/types/articleRes";

const baseURL = process.env.REACT_APP_BASE_URL;
const API_PATH = "/articles";

export const articleService = createApi({
  reducerPath: "articleService",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL + API_PATH }),
  endpoints: (builder) => ({
    getHomePageArticle: builder.query<
      ApiPageResponse<HomePageArticleRes[]>,
      { page: number; limit: number }
    >({
      query: (params) => {
        const { page, limit } = params;
        return `/homepage?offset=${(page - 1) * limit}&limit=${limit}`;
      },
    }),

    getArticleDetail: builder.query<
      ApiResponse<ArticleDetailRes>,
      { type: string; articleNumber: string }
    >({
      query: ({ type, articleNumber }) => {
        return `/${type}/${articleNumber}`;
      },
    }),

    updateArticleLikeCount: builder.mutation<ApiResponse<void>, string>({
      query: (id) => ({
        url: `/${id}/like-count`,
        method: "PATCH",
      }),
    }),

    getArticleTimeline: builder.query<ApiResponse<ArtilceTimelineRes[]>, void>({
      query: () => {
        return "/timeline";
      },
    }),
  }),
});

export const {
  useGetHomePageArticleQuery,
  useGetArticleDetailQuery,
  useUpdateArticleLikeCountMutation,
  useGetArticleTimelineQuery,
} = articleService;
