import { Link } from "react-router-dom";
import { ArticleProps } from "types/constantType";
import "./style.scss";

const Security1: React.FC<ArticleProps> = ({ getImagePath }) => {
  return (
    <article>
      <div className="main-content">
        <h2>在了解 JWT 之前，先來認識 Token</h2>
        <h5>什麼是 Token？</h5>
        <p>
          當使用者登入後（驗證成功），Server 端會給使用者一個 token，有了這個
          token，使用者下次登入就不用輸入帳號密碼了，而是改用 token 驗證，直到
          token 過期為止
        </p>
        <p>Token 可以是以 UUID 或 JWT 的格式</p>
        <p>舉一個使用者想請求帳戶的資訊作為例子：</p>
        <p>
          1. 使用者成功登入
          <br />
          2. server 端會生成 token，同時會把 token 資訊存在記憶體或資料庫當中
          <br />
          3. 使用者拿到屬於自己的 token
          <br />
          4. 此時使用者想查看自己的帳戶餘額，只需把 token 傳給 server
          端（而不用再次登入）
          <br />
          5. server 端驗證 token 通過後，接著把帳戶餘額的資訊回傳給使用者
        </p>
        <img src={getImagePath(1)} alt="token 登入流程圖" />
        <h2>Token 的優點</h2>
        <p>
          1. token
          讓我們在每次請求的時候，都不用輸入帳號密碼．如果頻繁的使用帳密去驗證而不是使用
          token 的方式，在資安上會有很大的危險
        </p>
        <p>
          2. token 可以<strong className="text-danger">設定有效時間</strong>
          ．舉例來說登入銀行APP，可以設定 5分鐘後 token 就失效，用來保證安全性
        </p>
        <p>
          3. token 可以
          <strong className="text-danger">儲存有關使用者的資訊</strong>，
          像是帳號、權限、角色等等
        </p>
        <blockquote>
          <p>一般的 token 是做不到的， JWT 才可以</p>
        </blockquote>
        <p>
          4. token 可以被<strong className="text-danger">重複使用</strong>
          ，像是今天我們只要登入 google，就可以使用 google 底下的東西例如
          gmail、google drive，而不用每次都重新去登入
        </p>
        <h2>什麼是 JWT</h2>
        <p>
          JWT 全名叫做：「JSON Web Token」，也就是說 JWT 的 token 是以 json
          格式來呈現
        </p>
        <p>
          JWT 可以用在登入及授權的場景，可以把使用者資訊存在 token
          裡面，這樣做可以減少 server 端的負擔
        </p>
        <p>JWT token主要由三個部分所組成，並且透過 . 來區隔</p>
        <p>
          <span className="header-bg-color">
            eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.
          </span>
          <br />
          <span className="payload-bg-color">
            eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.
          </span>
          <br />
          <span className="signature-bg-color">
            SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c
          </span>
        </p>
        <h5 className="mt-50">
          <span className="header-bg-color">header</span>
        </h5>
        <p>在 header 裡面，它包含簽名的演算法，以及token的類型，也就是 JWT</p>
        <p>然後這個 json 會被編碼成 JWT 的第一部分</p>
        <img src={getImagePath(2)} alt="jwt token header" />
        <h5>
          <span className="payload-bg-color">payload</span>
        </h5>
        <p>
          在 payload 裡面，它會<strong>儲存使用者的資訊</strong>
          ，像是姓名、信箱、角色、過期時間和誰簽這個 token等等
        </p>
        <p>然後這個 json 會被編碼成 JWT 的第二部分</p>
        <img src={getImagePath(3)} alt="jwt token payload" />
        <h5>
          <span className="signature-bg-color">signature (簽名)</span>
        </h5>
        <p className="text-danger">
          <strong>
            signature 的用途在於驗證消息的過程中，確認資料是否有被更改
          </strong>
        </p>
        <p>
          想一想假如你今天的權限是訪客的身份，如果沒有
          signature，你就可以隨意把你的權限改成管理者，這是非常危險的
        </p>
        <p>如果是使用 HMAC SHA256 算法，將按以下方式創建 signature</p>
        <blockquote>
          <p>可以看到最後面有一個 secret，後面會介紹到它</p>
        </blockquote>
        <p>然後這個 json 會被編碼成 JWT 的第三部分</p>
        <img src={getImagePath(4)} alt="jwt token signature" />
        <h2>secret</h2>
        <p>secret 中文我們可以稱為私鑰</p>
        <p>可以看到在產生 signature 的時候，最後面會帶一個 secret</p>
        <p>secret 是一個存放在 Server 端的自定義字串</p>
        <p>
          <strong className="text-danger">用來驗證 JWT、產生 signature</strong>{" "}
          （不能外流，不然對方就可以自己去產生 JWT ）
        </p>
        <h2>JWT.io</h2>
        <p>
          JWT 提供的
          <Link to="https://jwt.io/" target="blank">
            官方網站
          </Link>
          ，可以把產生的 JWT Token，拿去解碼，得到我們想要的資訊
        </p>
        <img src={getImagePath(5)} alt="jwt.io 官網" />
        <hr className="wp-block-separator is-style-wide" />
        <h2>參考資料</h2>
        <div className="references">
          <div>
            <Link to="https://jwt.io/" target="_blank">
              jwt.io
            </Link>
            <span>@JWT</span>
          </div>
          <div>
            <Link
              to="https://www.udemy.com/course/spring-security-zero-to-master/"
              target="_blank"
            >
              Spring Security 6 Zero to Master along with JWT,OAUTH2
            </Link>
            <span>@Udemy</span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Security1;
