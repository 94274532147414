import "./style.scss";

const Loading: React.FC = () => {
  return (
    <div className="preloader">
      <div className="circle"></div>
    </div>
  );
};

export default Loading;
