import { useRoutes } from "react-router-dom";
import routes from "./routes";
import NoticeBox from "components/Notice";

import "assets/scss/style.scss";
import "assets/scss/widget.scss";
import "assets/scss/responsive.scss";

function App() {
  const element = useRoutes(routes);
  return (
    <>
      {element}
      <NoticeBox />
    </>
  );
}

export default App;
