import { Link } from "react-router-dom";
import Gist from "react-gist";

import { ArticleProps } from "types/constantType";

const Java1: React.FC<ArticleProps> = ({ getImagePath }) => {
  return (
    <article>
      <div className="main-content">
        <h2>前言</h2>
        <p>
          這裡將會介紹如何使用 Spring Boot 和 OAuth 2.0 實現 Google
          登入功能。由於網路已經有太多相關的教學，但都是搭配 Spring
          Security，因此在這裡嘗試不使用 Spring Security
          來達成第三方登入，也能讓自己對 Authorization Code Flow 更為熟悉
        </p>
        <h2>建立 Goolge Oauth Credentials</h2>
        <p>進到 Google Cloud 的 API 和服務</p>
        <p>第一次進來要先創立專案，我這邊叫做 Oauth2 Login Demo</p>
        <p>創立完成後要先去 Oauth 同意畫面設定，跟著指示輸入資料就好</p>
        <p>上述步驟都完成後，點選建立憑證，這邊我們選擇 Oauth 用戶端 ID</p>
        <img src={getImagePath(1)} alt="" />
        <p>應用程式類型選擇網頁應用程式</p>
        <p>名稱隨意輸入</p>
        <p>重新導向 URL 可以先跟我設定一樣，之後可以彈性修改</p>
        <img src={getImagePath(2)} alt="" />
        <p>都輸入完成後，點選建立，接著把它提供的 JSON 下載下來</p>
        <img src={getImagePath(3)} alt="" />
        <p>
          JSON 打開後會長這樣，除了 project_id 和 auth_provider_x509_cert_url
          以外，其它我們都用得到
        </p>
        <Gist id="e685fdca220906b1a0425f598ddfbccf" file="setting.json" />
        <h2>Springboot Google 登入流程</h2>
        <p>
          Oauth2.0 的流程，我們採用其中一種類型叫做
          <strong>Authorization Code Flow</strong>
        </p>
        <img src={getImagePath(4)} alt="" />
        <p className="number-circle">1</p>
        <p>我們要拿 auth_uri 並帶上一些參數，向 google 請求同意授權的畫面</p>
        <Gist id="e685fdca220906b1a0425f598ddfbccf" file="auth.https" />
        <p>參數介紹：</p>
        <p>
          scope：存取用戶資料範圍 ( profile 和 email 取得用戶的基本資料和信箱 )
        </p>
        <p>response_type：告訴 Authorization Server 期望回應的類型</p>
        <p>redirect_uri：用戶同意授權，會重定向到這個 URL</p>
        <p>
          client_id：建立 Goolge Oauth Credentials 它幫我們產生的，這個 ID
          讓Google知道是哪個應用程式正在發出授權請求
        </p>
        <p className="number-circle">2</p>
        <p>第一步的 URL 會引導使用者到 Google 的登入頁面，並選擇是否同意授權</p>
        <p>這邊可以看到「Oauth2 Login Demo」，這就是我們當初建立的專案名稱</p>
        <p className="number-circle">3</p>
        <p>
          授權同意後，Authorization Server 會將用戶重定向到我們設定的
          redirect_uri，同時帶一組 code 給我們，注意這個 code 具有唯一性和時效性
        </p>
        <p className="number-circle">4</p>
        <p>
          此時我們要用 tokne_uri 帶著 code 向 Authorization Server 換取 Access
          token
        </p>
        <Gist id="e685fdca220906b1a0425f598ddfbccf" file="googleapis.https" />
        <p className="number-circle">5</p>
        <p>Authorization Server 驗證成功後，會回傳給我們 Access token</p>
        <p className="number-circle">6</p>
        <p>
          接著我們向 Resource Server
          發起請求來獲取使用者資訊，請求中要帶著剛剛拿到的 Access
          Token．關於可以取得哪些資訊，我們在步驟一的 scop 參數就有設定了
        </p>
        <Gist id="e685fdca220906b1a0425f598ddfbccf" file="userinfo.https" />
        <p className="number-circle">7</p>
        <p>Resource Server 回傳給我們使用者的資訊</p>
        <h2>SpringBoot 設定</h2>
        <p>設置 application.yaml</p>
        <Gist id="e685fdca220906b1a0425f598ddfbccf" file="application.yaml" />
        <p>
          這邊除了 user-info-uri，其他都是從剛剛下載的 JSON 檔可以得知的資訊
        </p>
        <p>設置 Controller</p>
        <Gist id="e685fdca220906b1a0425f598ddfbccf" file="controller.java" />
        <p>設置 Service</p>
        <Gist id="e685fdca220906b1a0425f598ddfbccf" file="service.java" />
        <h2>實際操作</h2>
        <p>
          輸入 http://localhost:8080/login/google，拿到 Google 授權同意的 URL
        </p>
        <img src={getImagePath(5)} alt="" />
        <p>輸入 URL 後，會引導我們到登入頁面</p>
        <p>
          這邊可以看到 「Oauth2 Login Demo」，也就是我們在建立 Goolge Oauth
          Credentials 的專案名稱
        </p>
        <img src={getImagePath(6)} alt="" />
        <p>
          登入成功和同意授權後，Authorization Server 會到重定向到
          http://localhost:8080/callback/google，並帶上 code
        </p>
        <p>成功拿到使用者的資訊了！！</p>
        <img src={getImagePath(7)} alt="" />
        <h2>參考資料</h2>

        <div className="references">
          <div>
            <Link to="https://developers.google.com/identity/protocols/oauth2?hl=zh-tw">
              OAuth 2.0 存取 Google API
            </Link>
            <span>@Google Doc</span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Java1;
