import React from "react";

import { ArticleProps } from "types/constantType";

import Java1 from "pages/Article/Java/Java1";
import Java2 from "pages/Article/Java/Java2";
import Java3 from "pages/Article/Java/Java3";
import Java4 from "pages/Article/Java/Java4";
import Java5 from "pages/Article/Java/Java5";
import Java6 from "pages/Article/Java/Java6";
import Java7 from "pages/Article/Java/Java7";
import Java8 from "pages/Article/Java/Java8";
import Java9 from "pages/Article/Java/Java9";
import Security1 from "pages/Article/Security/Security";
import Network1 from "pages/Article/Network/Network1";
import Network2 from "pages/Article/Network/Network2";
import Network3 from "pages/Article/Network/Network3";
import Network4 from "pages/Article/Network/Network4";
import Network5 from "pages/Article/Network/Network5";
import Network6 from "pages/Article/Network/Network6";
import Network7 from "pages/Article/Network/Network7";
import Reading1 from "pages/Article/Reading/Reading1";
import Database1 from "pages/Article/Database/Database1";
import Database2 from "pages/Article/Database/Database2";
import Other1 from "pages/Article/Other/Other1";
import Other2 from "pages/Article/Other/Other2";

export const articles: { [key: string]: React.FC<ArticleProps> } = {
  java1: Java1,
  java2: Java2,
  java3: Java3,
  java4: Java4,
  java5: Java5,
  java6: Java6,
  java7: Java7,
  java8: Java8,
  java9: Java9,
  security1: Security1,
  network1: Network1,
  network2: Network2,
  network3: Network3,
  network4: Network4,
  network5: Network5,
  network6: Network6,
  network7: Network7,
  reading1: Reading1,
  database1: Database1,
  database2: Database2,
  other1: Other1,
  other2: Other2,
};
