import { Link } from "react-router-dom";
import { ArticleProps } from "types/constantType";

const Java4: React.FC<ArticleProps> = ({ getImagePath }) => {
  return (
    <article>
      <div className="main-content">
        <h2>前言</h2>
        <p>
          關於這三者的比較，在面試中很常被問到，也發現自己要完全講出其中的差異，也講得哩哩拉拉(台語)，因此想說透過圖片的方式來加深自己的印象
        </p>
        <p>
          在開始之前，我們要先把把同步分成一類，然後非同步分成另一類，然後非同步底下有單執行緒和多執行緒
        </p>
        <blockquote>
          <p>這邊我們要意識到一件事情：單／多執行緒只是實現非同步的一種手段</p>
        </blockquote>
        <h2>先決條件</h2>
        <p>今天有3間航空公司，分別是：</p>
        <p>A 航空：代表的是同步</p>
        <p>B 航空：代表的是非同步(單執行緒)</p>
        <p>C 航空：代表的是非同步(多執行緒)</p>
        <p>
          然後 A 和 B 航空公司為了節省人力成本，都只請
          <strong className="text-danger">一個人</strong>來處理行李的托運
        </p>
        <p>
          C 航空公司則是請了<strong className="text-danger">三個人</strong>
          來處理行李的托運
        </p>
        <h2>同步(Sync)</h2>
        <p>
          A
          航空的老闆對處理託運的員工說：「一次只能處理一位乘客的行李，確定行李已經上了飛機才能處理下一位的，中間等待行李傳輸的時間就是你的休息時間」
        </p>
        <img src={getImagePath(1)} alt="同步" />
        <p>結論：等到上一個執行結束，才會去執行下一個</p>
        <h2>非同步(Async)</h2>

        <h5 className="mt-50">單執行緒</h5>
        <p>
          B
          航空的老闆對處理託運的員工說：「處理完一位行李的托運，在等待的時間緊接著處理下一位的行李，不要浪費等待的時間」
        </p>
        <img src={getImagePath(2)} alt="非同步" />
        <p>
          和 A
          航空的差異，後面排隊的乘客不用傻傻地等到前一位的行李上飛機才輪到他
        </p>
        <p>結論：同一個時間內可以做很多事情</p>
        <blockquote>
          <p>
            最常看到的是 JS 的{" "}
            <span className="code-highlight">setTimeout</span>、
            <span className="code-highlight">AJAX</span>
            ，比較特別的這裡並不會影響 JS 的主執行緒，而是交給瀏覽器來做
          </p>
        </blockquote>
        <h5 className="mt-50">多執行緒(Multi-Threading)</h5>
        <p>
          C
          航空老闆直接找來了3個員工來處理行李的托運，並且跟他們說：「處理完一位行李的托運，在等待的時間緊接著處理下一位的行李，不要浪費等待的時間」
        </p>
        <p>
          可以發現 C 航空的老闆和 B 航空老闆說的話
          <strong>一模一樣，只是他是同時對3個人說</strong>
        </p>
        <img src={getImagePath(3)} alt="多執行緒" />
        <p>結論：很多人(執行緒)同一個時間內可以做很多事情</p>
        <h2>非同步有比較快嗎</h2>
        <p>
          答案是沒有，但是非同步可以利用等待的時間去做其它事情，因此效能也跟著提升
        </p>
        <h2>同步和非同步的優缺點</h2>
        <p>同步的效率會比較低，花費時間更多</p>
        <p>同步是依順序執行，有利於我們對流程的控制</p>
        <p>非同步效率較高，更節省時間</p>
        <p>非同步佔用比較多的資源，有可能導致資源競爭和不一致的問題</p>

        <hr className="wp-block-separator is-style-wide" />
        <h2>參考資料</h2>
        <div className="references">
          <div>
            <Link
              to="https://www.baeldung.com/cs/async-vs-multi-threading"
              target="_blank"
            >
              The Difference Between Asynchronous and Multi-Threading
            </Link>
            <span>@Baeldung</span>
          </div>
          <div>
            <Link
              to="https://www.mendix.com/blog/asynchronous-vs-synchronous-programming/"
              target="_blank"
            >
              Asynchronous vs. Synchronous Programming: Key Similarities and
              Differences
            </Link>
            <span>@mendix</span>
          </div>
          <div>
            <Link to="https://medium.com/" target="_blank">
              Medium
            </Link>
            <span>@Medium</span>
          </div>
          <div>
            <Link to="https://www.flaticon.com/" target="_blank">
              ICON
            </Link>
            <span>@FLATICON</span>
          </div>
          <div>
            <Link
              to="https://www.youtube.com/watch?v=z4S6ZxRD2rA&ab_channel=%E8%B5%B0%E6%AD%AA%E7%9A%84%E5%B7%A5%E7%A8%8B%E5%B8%ABJames"
              target="_blank"
            >
              JavaScript Event Loop解說：單執行緒還能異步運算？
            </Link>
            <span>@Youtube-走歪的工程師James</span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Java4;
