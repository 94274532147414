import React from "react";

import "./style.scss";

const Footer: React.FC = () => {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <footer>
      <p>Copyright © {getCurrentYear()} Sam Huang. All Rights Reserved.</p>
    </footer>
  );
};

export default Footer;
