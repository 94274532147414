import { useState, Dispatch, SetStateAction } from "react";

interface UsePageClickReturnType {
  currentPage: number;
  handlePageClick: (event: any) => void;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

const usePageClick = (initialPage = 1): UsePageClickReturnType => {
  const [currentPage, setCurrentPage] = useState<number>(initialPage);

  const handlePageClick = (event: any) => {
    setCurrentPage(event.selected + 1);
  };

  return { currentPage, handlePageClick, setCurrentPage };
};

export default usePageClick;
