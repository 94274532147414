import { Link } from "react-router-dom";
import { ArticleProps } from "types/constantType";
import Gist from "react-gist";

const Database2: React.FC<ArticleProps> = ({ getImagePath }) => {
  return (
    <article>
      <div className="main-content">
        <h2>前言</h2>
        <p>
          相信大家對索引（Index）
          一定不陌生，當今天要查詢的資料過於龐大，因此我們可以透過索引的方式來減少查詢的時間，藉此提升資料庫的效能
        </p>
        <p>
          索引就像是一本書的目錄一樣，我們可以透過目錄快速的找到想要的章節或內容
        </p>
        <h2>前置作業</h2>
        <p>
          為了測試方便，在這裡我先建立一張 Student Table 並包含常見的索引類型
        </p>
        <p>
          並且會使用 <span className="code-highlight">explain</span> 對 select
          語句進行分析，讀者可以觀察 <span className="code-highlight">key</span>{" "}
          欄位是否有值
        </p>
        <blockquote>
          <p>有需要 SQL 的我已放在文章最下方</p>
        </blockquote>
        <p>
          為了看出是否真的命中索引，在所有的查詢條件都會加上
          <span className="code-highlight">explain</span>
        </p>
        <img src={getImagePath(10)} alt="資料庫索引失效" className="mt--50" />
        <h2>1. 使用 !=、&lt;&gt;、NOT、NULL</h2>
        <p>
          使用 <span className="code-highlight">!=</span> 或{" "}
          <span className="code-highlight">&lt;&gt;</span>{" "}
          會導致索引失效是因為它們定義了範圍查詢，這樣資料庫會進行全表掃描而不是索引
        </p>
        <Gist id="5f72ca78a196417d943826fe15bd02a0" file="example1.sql" />
        <img
          src={getImagePath(1)}
          alt="使用 !=、<>、NOT、NULL"
          className="mt--50"
        />
        <h2>2. 類型不匹配</h2>
        <p>
          當查詢的的欄位類型與索引類型不相同時，資料庫會進行隱式類型轉換，導致索引失效
        </p>
        <p>這裡的 name 是字串我卻用數字進行查詢</p>
        <Gist id="5f72ca78a196417d943826fe15bd02a0" file="example2.sql" />
        <img src={getImagePath(2)} alt="類型不匹配" className="mt--50" />
        <h2>3. 索引列上使用函數</h2>
        <p>
          使用函數後，資料庫需要對每一行的資料進行函數計算，而無法直接使用索引來加速查詢
        </p>
        <Gist id="5f72ca78a196417d943826fe15bd02a0" file="example3.sql" />
        <img src={getImagePath(3)} alt="索引列上使用函數" className="mt--50" />
        <h2>4. % 放在左側的模糊查詢</h2>
        <p>索引都會從最「左側」進行匹配，因此 % 放在左側會導致索引失效</p>
        <Gist id="5f72ca78a196417d943826fe15bd02a0" file="example4-1.sql" />
        <img
          src={getImagePath(4)}
          alt="% 放在左側的模糊查詢"
          className="mt--50"
        />
        <p>索引放在最後面導致索引有效</p>
        <blockquote>
          <p>要注意只有「部分」有效，實際情況可能不同</p>
        </blockquote>
        <Gist id="5f72ca78a196417d943826fe15bd02a0" file="example4-2.sql" />
        <img src={getImagePath(5)} alt="sql !=" className="mt--50" />
        <h2>5. 使用 or</h2>
        <p>
          <span className="code-highlight">or</span>{" "}
          連接的欄位必須指定為索引，只要有其中一個沒有被指定到就會失效
        </p>
        <Gist id="5f72ca78a196417d943826fe15bd02a0" file="example5-1.sql" />
        <img src={getImagePath(6)} alt="使用 or 索引失效" className="mt--50" />
        <p>id 和 name 皆為索引，索引有效</p>
        <Gist id="5f72ca78a196417d943826fe15bd02a0" file="example5-2.sql" />
        <img src={getImagePath(7)} alt="使用 or 索引成功" className="mt--50" />
        <h2>6. 複合式索引順序錯誤</h2>
        <p>
          如果今天複合式的索引為 &#123;A, B,
          C&#125;，那使用的索引的順序就必須要由左至右
        </p>
        <p>成功的組合如下</p>
        <p>
          <li>1. &#123;A&#125;</li>
          <li>2. &#123;A, B&#125;</li>
          <li>3. &#123;A, B, C&#125;</li>
        </p>
        <Gist id="5f72ca78a196417d943826fe15bd02a0" file="example6-1.sql" />
        <img
          src={getImagePath(8)}
          alt="複合式索引順序序正確 索引成功"
          className="mt--50"
        />
        <Gist id="5f72ca78a196417d943826fe15bd02a0" file="example6-2.sql" />
        <img
          src={getImagePath(9)}
          alt="複合式索引順序錯誤 索引失敗"
          className="mt--50"
        />
        <h2>7. 使用 select *</h2>
        <p>
          這邊比較特別，使用 <span className="code-highlight">*</span>{" "}
          並不會造成索引失效，但是會比只查索引欄位還來得慢
        </p>
        <Gist id="5f72ca78a196417d943826fe15bd02a0" file="example7-1.sql" />
        <img
          src={getImagePath(11)}
          alt="使用 select * 索引成功"
          className="mt--50"
        />
        <Gist id="5f72ca78a196417d943826fe15bd02a0" file="example7-2.sql" />
        <img
          src={getImagePath(12)}
          alt="使用 select 索引 索引成功 速度較快"
          className="mt--50"
        />
        <h2>SQL 參考</h2>
        <Gist id="5f72ca78a196417d943826fe15bd02a0" file="index-not-work.sql" />
        <h2>參考資料</h2>
        <div className="references">
          <div>
            <Link
              to="https://medium.com/johnliu-%E7%9A%84%E8%BB%9F%E9%AB%94%E5%B7%A5%E7%A8%8B%E6%80%9D%E7%B6%AD/database-%E5%85%AB%E5%80%8B-index-%E7%B4%A2%E5%BC%95-%E7%84%A1%E6%B3%95%E7%94%9F%E6%95%88%E7%9A%84-sql-%E5%AF%AB%E6%B3%95-cdc7d2e72f51"
              target="_blank"
            >
              Database — 八個 Index( 索引 ) 無法生效的 SQL 寫法
            </Link>
            <span>@Medium</span>
          </div>
          <div>
            <Link
              to="https://segmentfault.com/a/1190000021464570"
              target="_blank"
            >
              一张图搞懂MySQL的索引失效
            </Link>
            <span>@segmentfault</span>
          </div>
          <div>
            <Link
              to="https://segmentfault.com/a/1190000008131735"
              target="_blank"
            >
              MySQL 性能优化神器 Explain 使用分析
            </Link>
            <span>@segmentfault</span>
          </div>
          <div>
            <Link
              to="https://ithelp.ithome.com.tw/articles/10221971"
              target="_blank"
            >
              30-13 之資料庫層的優化 - 索引設計與雷區
            </Link>
            <span>@iThome</span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Database2;
