import { Link } from "react-router-dom";
import { ArticleProps } from "types/constantType";

const Network7: React.FC<ArticleProps> = ({ getImagePath }) => {
  return (
    <article>
      <div className="main-content">
        <h2>前言</h2>
        <p>
          想要先了解 Switch 和 Router 可以先參考{" "}
          <Link to="/article/network/6" target="blank">
            Hub、Switch 和 Router 的差異及運作原理
          </Link>
        </p>
        <h2>Router 區分網段</h2>
        <p>
          Router 有一個作用就是<strong>區分網段</strong>
        </p>
        <p>以下方的圖作為例子</p>
        <p>Switch1 是 192.168.1.0 的網段，A、B 、C 電腦都在這一個網段底下</p>
        <p>Switch2 是 192.168.2.0 的網段，D、E、F 電腦都在這一個網段底下</p>
        <h5>網段相同：</h5>
        <p>
          今天當 A 電腦想要傳封包給 B 電腦，在經過 Switch 的時候，發現都在
          192.168.1.0 這個網段底下，因此不用經過 Router，就可以直接傳遞封包
        </p>
        <h5>網段不同：</h5>
        <p>
          但當今天 A 電腦想要傳封包給 D 電腦，在經過
          Switch後，發現彼此的網段不同，因此就需要經過 Router 來將封包傳給 D
          電腦
        </p>
        <img src={getImagePath(1)} alt="Router路由器" />
        <h2>traceroute 指令</h2>
        <p>trareroute：追蹤路由及查詢路線</p>
        <p>
          今天我們在自己的電腦上想要去訪問 Google 首頁，那其中會經過非常多台的
          Router，不會只經過一台
        </p>
        <img src={getImagePath(2)} alt="Router路由器" />
        <p>
          當我們想要追蹤路由，就可以在 Terminal 中輸入{" "}
          <span className="bg-highlight-grey">traceroute www.google.com</span>
          指令
        </p>
        <blockquote>
          <p>
            使用 Windows 的人則可以輸入{" "}
            <span className="bg-highlight-grey">tracert www.google.com</span>
          </p>
        </blockquote>
        <p>要連到 Google 首頁之前，可以發現中間經過了 11 台 Router</p>
        <p>
          如果發現訪問網頁的速度變慢，那我們可以查看 log
          後面的時間看到底是慢在哪裡（每台設備 traceroute 會幫我們測試 3次）
        </p>
        <img src={getImagePath(3)} alt="traceroute 指令結果" />
        <hr className="wp-block-separator is-style-wide" />
        <h2>參考資料</h2>
        <div className="references">
          <div>
            <Link
              to="https://www.hellotech.com/guide/for/how-to-run-a-traceroute-mac"
              target="_blank"
            >
              How to Run a Traceroute on a Mac
            </Link>
            <span>@HelloTech</span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Network7;
