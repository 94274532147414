import { Link } from "react-router-dom";
import { ArticleProps } from "types/constantType";
import Gist from "react-gist";
import GitHubLink from "../components/GithubLink";

const Java5: React.FC<ArticleProps> = ({ getImagePath }) => {
  return (
    <article>
      <div className="main-content">
        <h2>前言</h2>
        <p>
          在現代軟體開發的過程中，<strong>高性能</strong>
          一直是我們追求的目標之一
        </p>
        <p>自己是不是也有曾經有想過下面幾個問題</p>
        <ul>
          <li>for loop 和 stream 哪個快？</li>
          <li>if 快還是 switch 快？</li>
          <li>HashMap 的初始化 size 要不要指定？</li>
          <li>各種序列化方法哪個運行時間更短？</li>
        </ul>
        <p>
          不管我們的問題是什麼，都是想要讓程式碼在運作時速度更快，哪怕只快0.0001秒
        </p>
        <p>這時候我們的主角就登場了，也就是「JMH」</p>
        <h2>什麼是基準測試</h2>
        <p>
          在軟體開發的時候只聽過單元測試、整合測試，但「基準測試」卻從來沒聽過，直到接觸了
          JMH
        </p>
        <p>
          這裡我就直接引用
          <Link
            to="https://baike.baidu.hk/item/%E5%9F%BA%E6%BA%96%E6%B8%AC%E8%A9%A6/5876292"
            target="blank"
          >
            百度
          </Link>
          的介紹
        </p>
        <blockquote>
          <p>
            基準測試是指通過設計科學的測試方法、測試工具和測試系統，實現對一類測試對象的某項
            <strong>性能指標</strong>進行定量的和可對比的測試
          </p>
        </blockquote>
        <h2>JMH 介紹</h2>
        <p>
          JMH(Java Microbenchmark
          Harness)是一個基準測試工具。它主要用於測量方法或片段程式碼的運行性能，精準度可以達到奈秒等級
        </p>
        <blockquote>
          <p>
            該工具是由 OpenJDK 團隊裡面的大神們所編寫的，他們絕對比任何人都了解
            JIT 以及 JVM 對於基準測試的影響
          </p>
        </blockquote>
        <h2>為什麼要選擇 JMH</h2>
        <p>相信大家在認識 JMH 之前，都寫過類似下面的程式碼</p>
        <Gist id="c0cfd0adc973c0fc6f4a3d3b96f10313" file="list-time.java" />
        <p>這樣做本身沒有什麼問題，但是這樣的測量結果，不一定準確</p>
        <p>
          想要得到穩定的結果之前，我們一定要先進行<strong>預熱</strong>
          ，預熱前和預熱後性能會差非常大
        </p>
        <blockquote>
          <p>
            為什麼需要預熱？如果某個函數被呼叫多次之後，JVM
            會嘗試將其編譯為機器碼，從而提高執行速度，所以為了讓 benchmark
            的結果更加接近真實情況就需要進行預熱，有興趣的可以參考
            <Link
              target="_blank"
              to="https://medium.com/@ssuchieh/%E6%B7%BA%E8%AB%87-java-%E7%9B%B8%E9%97%9C%E8%AA%9E%E8%A8%80%E7%9A%84-application-performance-improvement-%E7%B3%BB%E5%88%97-2-7d5b23298746"
            >
              這篇
            </Link>
          </p>
        </blockquote>
        <p>
          而且每次要去計算我們都要寫手動寫
          <span className="code-highlight">System.currentTimeMillis</span>
          ，這樣非常的耗時且程式碼也不容易管理
        </p>
        <h2>使用 JMH 的前置作業</h2>
        <p>導入 Maven 依賴</p>
        <Gist id="c0cfd0adc973c0fc6f4a3d3b96f10313" file="JMH-Maven.xml" />
        <p>
          如果怕版本太舊的可以在 Maven Repository 找到最新的{" "}
          <Link
            target="_blank"
            to="https://mvnrepository.com/artifact/org.openjdk.jmh/jmh-core"
          >
            JHM Core
          </Link>{" "}
          和{" "}
          <Link
            target="_blank"
            to="https://mvnrepository.com/artifact/org.openjdk.jmh/jmh-generator-annprocess"
          >
            JMH Annotation Processor
          </Link>
        </p>
        <blockquote>
          <p>我這裡使用的是 JDK17 以及 SpringBoot 3.2.1</p>
        </blockquote>
        <h2>JHM 範例</h2>
        <p>
          今天我想要知道，從 1 加到 1000萬，如果使用{" "}
          <span className="code-highlight">for loop</span> 和{" "}
          <span className="code-highlight">stream</span>
          這兩種方式，哪一種速度會比較快
        </p>
        <blockquote>
          <p>
            這邊我只列出比較常用的 annotation，如果想更知道更多 annotaion
            的用法以及它們還可以帶什麼參數，請參考
            <Link
              to="https://juejin.cn/post/7031008727645831176#heading-12"
              target="blank"
            >
              這篇
            </Link>
          </p>
        </blockquote>
        <Gist
          id="c0cfd0adc973c0fc6f4a3d3b96f10313"
          file="StreamVsLoopBenchmark.java"
        />
        <h5 className="mt-50">執行結果</h5>
        <img className="mt-30" src={getImagePath(1)} alt="JMH的執行結果" />
        <p>
          可以得知總和1000萬筆的數據時，使用{" "}
          <span className="code-highlight">for loop</span>
          的速度比較快
        </p>
        <p>
          解釋一下這個測試結果，其實主是根據我們在 class
          上方的配置，系統就會照我們的配置輸出
        </p>
        <ul>
          <li>Benchamrk：執行基準測試的 function</li>
          <li>Mode：模式，avgt 代表的是 average time(平均時間)</li>
          <li>Cnt：count 執行次數</li>
          <li>Score：測試的結果，這邊顯示的單位為奈秒</li>
          <li>Error：誤差</li>
          <li>Units：單位， ns/op代表 nanoseconds per operation</li>
        </ul>
        <h2>將結果圖形化</h2>
        <p>
          如果發現文字的結果難以解讀，那我們可以將測試的數據進行二次處理，並以圖表的形式進行展示
        </p>
        <p>
          在執行 build 的時候如果有添加{" "}
          <span className="code-highlight">
            .resultFormat(ResultFormatType.JSON)
          </span>{" "}
          這段程式碼，那我們就會在專案目錄底下得到{" "}
          <span className="code-highlight">jmh-result.json</span> 這份檔案
        </p>
        <img
          style={{ width: "60%" }}
          src={getImagePath(2)}
          alt="JMH的執行結果的json檔案"
        />
        <p>
          接著我們到{" "}
          <Link target="blank" to="https://jmh.morethan.io/">
            JMH Visualizer
          </Link>
          ，然後將我們的 JSON 檔丟上去，就可以看到圖形化的結果了
        </p>
        <img src={getImagePath(3)} alt="JMH的執行結果(圖性化)" />
        <h2>結論</h2>
        <p>
          希望能藉由這篇文章的介紹讓大家更了解 JMH
          這個工具，如果之後大家對於自己的程式碼有多種不同的想法，但又不知道哪一種比較快的時候，不妨試試
          JMH，或許看似為無不足道的改變，就能顯著影響程式運行的效率
        </p>
        <GitHubLink />
        <h2>參考資料</h2>
        <div className="references">
          <div>
            <Link
              to="https://www.baeldung.com/java-streams-vs-loops"
              target="_blank"
            >
              Streams vs. Loops in Java
            </Link>
            <span>@Baeldung</span>
          </div>
          <div>
            <Link
              to="https://www.baeldung.com/java-microbenchmark-harness"
              target="_blank"
            >
              Microbenchmarking with Java
            </Link>
            <span>@Baeldung</span>
          </div>
          <div>
            <Link
              to="https://juejin.cn/post/6844903936839647245"
              target="_blank"
            >
              [基准测试] JMH 简单入门
            </Link>
            <span>@稀土掘金</span>
          </div>
          <div>
            <Link to="https://www.zhihu.com/question/276455629" target="_blank">
              为什么要用JMH？何时应该用？
            </Link>
            <span>@知乎</span>
          </div>
          <div>
            <Link
              to="https://juejin.cn/post/7031008727645831176#heading-12"
              target="_blank"
            >
              顶级Java才懂的，基准测试JMH！
            </Link>
            <span>@稀土掘金</span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Java5;
