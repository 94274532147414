import { Link } from "react-router-dom";
import { ArticleProps } from "types/constantType";
import Gist from "react-gist";

const Network3: React.FC<ArticleProps> = ({ getImagePath }) => {
  return (
    <article>
      <div className="main-content">
        <h2>前言</h2>
        <p>
          在學習本章之前，有興趣的人可以先瞭解
          <Link target="_blank" to="/article/network/5">
            OSI 七層架構
          </Link>
          、
          <Link target="_blank" to="/article/network/2">
            Mac Address
          </Link>
          介紹
        </p>
        <h2>什麼是封包?</h2>
        <p>
          <strong>封包 (Packet) 是網路傳輸的最小資訊單位</strong>
        </p>
        <p>
          今天電腦收到資料，會把資料劃分成好幾個封包，然後這些封包會由接收它們的電腦或裝置重新組合成資料
        </p>
        <p>
          假設今天 Sam 想寫一封信寄給
          Alice，可以看到在寄信的途中，信被切分成四小部分，也就是封包，當 Alice
          收到信後，會把這四個封包組裝起來，再去讀這封信
        </p>
        <img src={getImagePath(1)} alt="封包" />
        <img
          style={{ maxWidth: "75%" }}
          className="mt-0"
          src={getImagePath(2)}
          alt="封包"
        />
        <h2>封包標頭</h2>
        <p>
          封包標頭會提供<strong>封包的內容、來源 IP 和目的 IP 等等</strong>
        </p>
        <p>
          網路上有一堆封包，要怎麼怎麼知道這個封包要傳給誰，因此就會找到標頭裡面的目的IP，並且將這封包傳到目的地(這邊用姓名來取代目的
          IP)
        </p>
        <p>
          當 Alice 收到 Sam 傳來的 4
          個封包的時候，她還要組合起來，因此她會對照封包標頭最前面的數字，依照順序的去把這些封包組合起來，拼湊出完整的內容
        </p>
        <img src={getImagePath(3)} alt="帶有標籤和註釋的詳細封包標頭圖表" />
        <h2>封包標頭從哪裡來</h2>
        <p>
          當數據在網路中傳輸時，會經過 OSI 定義的 7
          層架構，封包會在經過的每一層都會添加自己的標頭，直到最下面一層，進而去生成完整的封包
        </p>
        <img
          src={getImagePath(4)}
          alt="每層都明確標記的OSI 7層模型的圖形表示"
        />
        <p>接著來介紹每一層做的事</p>
        <h5 className="network3-color-orange">
          第５、６、７層-FTP/HTTP Header
        </h5>
        <p>存放要傳遞的資訊</p>
        <h5 className="network3-color-green">第４層-TCP/UDP Header</h5>
        <p>存放來源 Port 及目的 Port</p>
        <p>
          補充 IP 和 Port 的關係： <br />
          假設我打開三台 Terminal，並且用第一台 Terminal 連線到 FTP Server，當
          FTP Server 要回傳資料時，它可以透過 IP
          找到我們這台電腦，找到電腦後還需要 Port 進而去判斷要回到哪一個
          Terminal
        </p>
        <img
          src={getImagePath(5)}
          alt="每層都明確標記的OSI 7層模型的圖形表示"
        />
        <h5 className="network3-color-red">第３層-IP Header</h5>
        <p>存放來源 IP 及目的 IP</p>
        <h5 className="network3-color-yellow">第２層-Ethernet Header</h5>
        <p>存放來源 MAC 及目的 MAC</p>
        <h5 className="network3-color-yellow">第１層</h5>
        <p>實體層把 0101 二進制碼傳送出去</p>
        <h2>如何使用 Wireshark 抓取封包</h2>
        <p>
          Wireshark
          是一個功能十分強大開源的網路封包剖析器，可即時從網路介面擷取封包中的資料
        </p>
        <p>
          下載
          <Link to="https://www.wireshark.org/download.html" target="_blank">
            {" "}
            Wireshark
          </Link>
        </p>
        <p>打開終端機，輸入 ftp 66.220.9.51，此時還不要按下 Enter</p>
        <p>
          下載好 Wireshark 後，我們打開它，點擊上方的
          Capture，選擇要抓封包的網卡(Traffic 可以看到網路的流量)，然後按下
          Start 開始監控
        </p>
        <img
          src={getImagePath(6)}
          alt="突出顯示菜單選項和捕獲過程的Wireshark工具界面截圖"
        />
        <p>
          此時到你的終端機上，把剛剛輸入的 ftp 66.220.9.51 按下
          Enter，接著輸入帳號及密碼，帳號和密碼隨便輸入就好，因為我們只是要去看封包的資訊而已
        </p>
        <Gist id="20695f4cd4f6777d89d82f287f2b8323" file="packet.txt" />
        <div className="packet-info">
          <h2>查看 Wireshark 封包層級資訊</h2>
          <p>
            回到 Wireshark 上，點擊上方的
            Stop，再來篩選我們要的資訊，在搜尋欄輸入 ftp
          </p>
          <img
            src={getImagePath(7)}
            alt="在Wireshark界面上顯示封包捕獲及其各自標頭的詳細視圖"
          />
          <ul>
            <li>
              <h5>Ethernet II</h5>
              <p>此處顯示來源 MAC 以及目的 MAC</p>
              <img
                src={getImagePath(8)}
                alt="在Wireshark界面上顯示封包捕獲及其各自標頭的詳細視圖"
              />
            </li>
            <li>
              <h5>Internet Protocol Version 4</h5>
              <p>此處顯示來源 IP 以及目的 IP</p>
              <img
                src={getImagePath(9)}
                alt="在Wireshark界面上顯示封包捕獲及其各自標頭的詳細視圖"
              />
            </li>
            <li>
              <h5>Transmission Control Protocol</h5>
              <p>此處顯示來源 Port 及目的 Port</p>
              <img
                className="mt-20"
                src={getImagePath(10)}
                alt="在Wireshark界面上顯示封包捕獲及其各自標頭的詳細視圖"
              />
            </li>
            <li>
              <h5>File Transfer Protocol</h5>
              <p>此處顯示我們傳送的資料</p>
              <img
                className="mt-20"
                src={getImagePath(11)}
                alt="在Wireshark界面上顯示封包捕獲及其各自標頭的詳細視圖"
              />
            </li>
          </ul>
        </div>
        <p>
          可以發現除了我用紅色框框標記起來的地方，每一層的 Header
          還有其它更詳細的資訊，有興趣的人也可以深入研究
        </p>
        <hr className="wp-block-separator is-style-wide" />

        <h2>參考資料</h2>
        <div className="references">
          <div>
            <Link
              to="https://docs.tigera.io/calico/3.25/about/about-networking#anatomy-of-a-network-packet"
              target="_blank"
            >
              About Networking
            </Link>
            <span>@TIGERA</span>
          </div>
          <div>
            <Link to="https://www.wireshark.org/" target="_blank">
              wireshark
            </Link>
            <span>@WIRESHARK</span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Network3;
