import { Link } from "react-router-dom";

import "./style.scss";

interface ErrorProps {
  errorCode: string;
  title: string;
  linkTitle: string;
  link: string;
}

const Error: React.FC<ErrorProps> = (props: ErrorProps) => {
  return (
    <>
      <section className="error">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 ">
              <div className="col-sm-10 col-sm-offset-1 text-center mx-auto">
                <div className="picture-block">
                  <h1 className="text-center ">{props.errorCode}</h1>
                </div>
                <div className="text-box">
                  <h3 className="h2">{props.title}</h3>
                  <Link to={props.link} className="link">
                    {props.linkTitle}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Error;
