import { Link } from "react-router-dom";
import { ArticleProps } from "types/constantType";

const Network4: React.FC<ArticleProps> = ({ getImagePath }) => {
  return (
    <article>
      <div className="main-content">
        <h2>前言</h2>
        <p>
          在學習網路時很常聽到 Public IP、Private IP、IPv4、IPv6、Static
          IP、Dynamic
          IP...等，不太確定它們之間的差異在哪裡，今天要介紹的是根據分配方式來區分的
          Static IP 和 Dynamic IP
        </p>
        <>
          <h2>Static IP</h2>
          <p>Static IP 翻成中文叫做「靜態 IP」</p>
          <p>顧名思義就是不會動的 IP</p>
          <p>
            通常在連線印表機或傳真機的時候會有固定 IP
            的設定，不然每次要影印東西都要重新設定會麻煩許多
          </p>
          <ul className="mt-25">
            <h5>優點：</h5>
            <li>架站的時候適合使用</li>
            <h5>缺點：</h5>
            <li>因為 IP 是固定的，因此比較容易受到駭客攻擊</li>
            <li>價格比較貴</li>
          </ul>
          <h2>Dynamic IP</h2>
          <p>浮動 IP 翻成中文叫做「浮動 IP」</p>
          <p>IP 是隨機，一般的用戶比較適合使用浮動 IP</p>
          <p>IP 會隨著電腦開關機或是 ISP 業者重新發派 IP 而異動</p>
          <p>
            因為浮動 IP 都會有租期所在，租期一到就會將 IP
            收回，因此會斷線然後給予重新連線（舉例來說中華電的PPPOE浮動 IP
            大約是 72 個小時換一次，時間通常在半夜）
          </p>
          <ul className="mt-25">
            <h5>優點：</h5>
            <li>
              價格較便宜 設定較簡單，只需要將網卡下的 TCP/IP 設定自動取得 IP
              即可
            </li>
            <h5>缺點：</h5>
            <li>對於架站的人不那麼友善</li>
          </ul>
        </>

        <hr className="wp-block-separator is-style-wide" />

        <h2>參考資料</h2>
        <div className="references">
          <div>
            <Link
              to="https://www.mobile01.com/topicdetail.php?f=110&t=750030"
              target="_blank"
            >
              Mobile01討論
            </Link>
            <span>@MOBILE01</span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Network4;
