import { Link } from "react-router-dom";
import { formatDate } from "utils/helper";

type ItemProps = {
  item: {
    id: string;
    articleNumber: number;
    title: string;
    likeCount: number;
    readingTime: number;
    createTime: number;
    categoryName: string;
  };
};

const Item: React.FC<ItemProps> = ({ item }) => {
  const category = item.categoryName;
  const articleLink = `/article/${category}/${item.articleNumber}`;
  const categoryLink = `./category/${category}`;
  const articleNumber = item.articleNumber;

  const getImagePath = (category: string, articleNumber: number) => {
    try {
      return require(`../../assets/imgs/article/${category}/${articleNumber}/${category}${articleNumber}.png`);
    } catch (error) {
      return require(`../../assets/imgs/other/loading.png`);
    }
  };

  return (
    <>
      <article className="col-lg-4 col-md-6 fadeInUp">
        <div className="post-card hover-up">
          <div
            className="post-thumb img-overlay img-hover-slide position-relative"
            style={{
              backgroundImage: `url(${getImagePath(category, articleNumber)})`,
            }}
          >
            <Link className="img-link" to={articleLink}></Link>
          </div>
          <div className="post-content">
            <div className="tags">
              <Link to={categoryLink}>
                <span className={`tag ${category}-tag-color`}>{category} </span>
              </Link>
            </div>
            <div className="d-flex post-card-content">
              <h5 className="post-title">
                <Link to={articleLink}>{item.title}</Link>
              </h5>
              <div className="post-date-read-time">
                <span className="post-on">{formatDate(item.createTime)}</span>
                <span className="time-reading has-dot">
                  {item.readingTime} mins read
                </span>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default Item;
