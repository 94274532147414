import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiPageResponse } from "utils/apiResponse";
import { CategoryArticleRes } from "types/apiResponse/categoryRes";

const baseURL = process.env.REACT_APP_BASE_URL;
const API_PATH = "/categories";

interface CategoryArticlesQueryParams {
  type: string;
  page: number;
  limit: number;
}

export const categoryService = createApi({
  reducerPath: "categoryService",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL + API_PATH }),
  endpoints: (builder) => ({
    getArticlesByCategory: builder.query<
      ApiPageResponse<CategoryArticleRes[]>,
      CategoryArticlesQueryParams
    >({
      query: ({ type, page, limit }) => {
        return `/${type}?offset=${(page - 1) * limit}&limit=${limit}`;
      },
    }),
  }),
});

export const { useGetArticlesByCategoryQuery } = categoryService;
