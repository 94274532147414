import React from "react";

import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { CgMail } from "react-icons/cg";

import Header from "components/Header";
import Footer from "components/Footer";

import profileImage from "assets/imgs/author/sam2.png";
import "./style.scss";

const About: React.FC = () => {
  return (
    <>
      <Header />
      <main className="about-container">
        <div className="profile-section">
          <img src={profileImage} alt="Sam" className="profile-image" />
          <p className="profile-description">求知若飢，虛心若愚</p>
          <div className="social-links">
            <a
              href="https://www.facebook.com/yi.s.huang.98/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookSquare />
            </a>
            <a
              href="https://www.linkedin.com/in/sam-huang-571311246/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin />
            </a>
            <a
              href="https://github.com/samtash1034?tab=repositories&q=&type=&language=&sort="
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub />
            </a>
            <a
              href="mailto:samtash1034@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <CgMail />
            </a>
          </div>
        </div>
        <div className="about-me-section">
          <div className="about-me-block">
            <h2>關於我</h2>
            <p>
              大家好，我叫做
              Sam，目前是一名後端工程師，希望能將自己的所學一點一滴的記錄下來
            </p>
            <p>
              如果看完文章覺得有哪裡寫得不好或是疑惑的地方，請不要吝嗇的讓我知道
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default About;
