import { Link } from "react-router-dom";
import { ArticleProps } from "types/constantType";
import Gist from "react-gist";
import GitHubLink from "../components/GithubLink";

const Java7: React.FC<ArticleProps> = ({ getImagePath }) => {
  return (
    <article>
      <div className="main-content">
        <h2>前言</h2>
        <p>
          這次的主題比較龐大，因此拆分為上下兩部分，讓讀者可以分兩次來看，也不會一次閱讀太多吸收不了
        </p>
        <h2>HashMap 在存資料時實際上做了什麼事情</h2>
        <Gist id="34d099b04b90710ecf39f459cd6a013b" file="map-put.java" />
        <p>
          相信大家對上面這段程式碼並不陌生，只是簡單的將 key-value 塞入到
          HashMap 中
        </p>
        <p>這時 HashMap 主要會做三件事情</p>
        <p>1.將我們的 key 透過 hash function 變成雜湊值</p>
        <p>2.用這個雜湊值就算出一個索引值</p>
        <blockquote>
          <p>
            索引值的計算這邊就不深入探討，通常是根據雜湊表(Hash
            Table)的大小，並且取餘數
          </p>
        </blockquote>
        <img src={getImagePath(1)} alt="hashTable" />
        <p>3.根據 index 找到在 Hash Table 的相對應位置，並把資料塞進去</p>
        <img src={getImagePath(2)} alt="hashTable" />
        <blockquote>
          <p>
            眼尖的人可能已經發現，
            <span className="code-highlight">hash(a)</span> 和{" "}
            <span className="code-highlight">hash(c)</span> 所產生的 index
            都同樣為 <span className="code-highlight">0</span>
          </p>
        </blockquote>
        <h2>Map Collision 是什麼</h2>
        <p>Collison 翻成中文就是「碰撞」</p>
        <p>因此 Map Collision 最常見的說法就是「雜湊衝突」、「雜湊碰撞」</p>
        <p>
          如果兩個不同的 key 最終產生的 index
          一模一樣，這種情況我們就稱為「雜湊碰撞」
        </p>
        <p>當碰撞出現的時候，主要透過以下常見的方法去處理：</p>
        <p>封閉尋址法（Closed Addressing）</p>
        <p>開放尋址法（Open Addressing）</p>
        <ul>
          <li>線性探測（Linear Probing）</li>
          <li>二元探測（Quadratic Probing）</li>
          <li>雙重雜湊（Double Hashing）</li>
        </ul>
        <blockquote>
          <p>
            這篇主要會介紹「封閉尋址法」，對「開放尋址法」有興趣的人可以參考
            <Link to={"/article/java/8"}>這篇</Link>
          </p>
        </blockquote>
        <h2>封閉尋址法（Closed Addressing）</h2>
        <p>
          仔細思考這個方法的名稱，它希望「封閉」尋址，也就是不往外找其它的位址存放，而是延續現有的儲存空間做存放
        </p>
        <p>
          我們已經知道 <span className="code-highlight">hash(a)</span> 和{" "}
          <span className="code-highlight">hash(c)</span> 所產生的 index
          相同，此時就會發生碰撞，因此我們就會用一條 Linked List
          ，來存放碰撞的值
        </p>
        <blockquote>
          <p>可以把 Linked List 想像成火車，一節車廂接著一節</p>
        </blockquote>
        <img src={getImagePath(3)} alt="封閉尋址法（Closed Addressing）" />
        <h2>Java 遇到 HashMap Collision 時會怎麼處理？</h2>
        <p>其實 Java 對於衝突的解決機制，就是透過「封閉尋址法」來解決的</p>
        <p>
          但是如果當我們的 Linked List 太長，則會自動將 Linked List
          轉成紅黑樹(Red-Black-Tree)的方式來儲存，藉此來提升查詢的速度
        </p>
        <blockquote>
          <p>Linked List 轉換成 Red Black Tree 我們可以統稱為「treeify」</p>
        </blockquote>
        <img src={getImagePath(4)} alt="紅黑樹(Red-Black-Tree)" />
        <h5>Linkied List 太長的標準為何？</h5>
        <p>
          進到 HashMap 的源碼可以看到有一個參數叫做{" "}
          <span className="code-highlight">TREEIFY_THRESHOLD</span> ，並且預設為
          8
        </p>
        <p>
          也就是說當我們的 Linked List 的長度大於等於 8 時，就會自動轉換成 Red
          Black Tree
        </p>
        <img src={getImagePath(5)} alt="TREEIFY_THRESHOLD" />
        <h2>結論</h2>
        <GitHubLink />
        <h2>參考資料</h2>
        <div className="references">
          <div>
            <Link
              to="https://stackoverflow.com/questions/47921663/when-and-how-does-hashmap-convert-the-bucket-from-linked-list-to-red-black-trees"
              target="_blank"
            >
              HashMap convert the bucket from linked list to Red Black Trees
            </Link>
            <span>@Stack Overflow</span>
          </div>
          <div>
            <Link
              to="https://rickbsr.medium.com/%E6%B7%BA%E8%AB%87-hash-hashtable-%E8%88%87-hashmap-4e5f5e5d36da"
              target="_blank"
            >
              淺談「Hash」、「Hashtable」與「HashMap」
            </Link>
            <span>@Medium</span>
          </div>
          <div>
            <Link
              to="https://learnloner.com/how-does-a-hash-map-handle-collisions-in-java/"
              target="_blank"
            >
              How does a Hash map handle collisions in JAVA.
            </Link>
            <span>@learnloner</span>
          </div>
          <div>
            <Link
              to="https://medium.com/@ralph-tech/%E8%B3%87%E6%96%99%E7%B5%90%E6%A7%8B%E5%AD%B8%E7%BF%92%E7%AD%86%E8%A8%98-%E9%9B%9C%E6%B9%8A%E8%A1%A8-hash-table-15f490f8ede6"
              target="_blank"
            >
              資料結構學習筆記：雜湊表（Hash Table）
            </Link>
            <span>@Medium</span>
          </div>
          <div>
            <Link
              to="https://www.youtube.com/watch?v=vPvxEDyxI2w&list=PLhxdaTcUMi3nRM5mtOdQgO4VEtAEsTiYd&index=21&t=304s&ab_channel=%E5%A5%AEgame%E7%8E%8B%E7%B4%AB%E6%A5%93"
              target="_blank"
            >
              輕鬆搞懂資料結構: 雜湊(hash)
            </Link>
            <span>@Youtube</span>
          </div>
          <div>
            <Link to="https://hackmd.io/@hmllrb/H148c623u" target="_blank">
              深入探討jdk1.8版本HashMap機制之效能分析及其改良
            </Link>
            <span>@hackmd</span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Java7;
