import Error from "../../../components/Error";

const InnerServerError: React.FC = () => {
  return (
    <Error
      errorCode="500"
      title="如果看到請通知我一聲，謝謝您！"
      linkTitle="關於我"
      link="/about"
    />
  );
};

export default InnerServerError;
